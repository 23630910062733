import React from 'react';
import PropTypes from 'prop-types';

const LayoutShowBoxBlue = (props) => (
    <div className="layout__showBoxBlue seaBox" >
        { props.children }
    </div>
);

LayoutShowBoxBlue.propTypes = {
    children: PropTypes.node
};
LayoutShowBoxBlue.layoutPartName = "showBoxBlue";

export {
    LayoutShowBoxBlue
};
