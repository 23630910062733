// var dashboardVersion = require('./dashboard-version');
// var extendedFaker = require('./extended-faker');
var SideMenuAnimate = require('./side-menu-animate');
var SidebarEntryAnimate = require('./sidebar-entry-animate');
var SlimSidebarAnimate = require('./slim-sidebar-animate');
var SlimMenuAnimate = require('./slim-menu-animate');
// var DropdownMenuAnimate = require('./dropdown-menu-animate');
// var NestedDropdownAnimate = require('./nested-dropdown-animate');
// var HighchartsTheme = require('./highcharts');
// var Colors = require('./colors');

module.exports = {
    // dashboardVersion: dashboardVersion,
    // faker: extendedFaker,
    SideMenuAnimate: SideMenuAnimate,
    SidebarEntryAnimate: SidebarEntryAnimate,
    SlimSidebarAnimate: SlimSidebarAnimate,
    SlimMenuAnimate: SlimMenuAnimate,
    // DropdownMenuAnimate: DropdownMenuAnimate,
    // NestedDropdownAnimate: NestedDropdownAnimate,
    // HighchartsTheme: HighchartsTheme,
    // Colors: Colors,
};
