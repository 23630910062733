// GiveawayDefault
import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Badge, Button
} from '../../../components';

class GiveawayDefault extends React.Component {

    render() {
        return (
            <Container>
                <Row className="align-items-center justify-content-xl-between">
                    <div style={{width: "100%", fontSize: "40px", fontWeight: "800", textAlign: "center"}}>
                        Click to Get Monster Redemption Vouchers
                    </div>
                    <Container className="accordion">
                        <div style={{width: "100%", textAlign: "center"}}>
                            <img src="/img/TreasureChest/Open5.png"/>
                        </div>
                        <div style={{width: "100%", textAlign: "center"}}>
                            <Badge color="dark" pill style={{padding:"10px"}}>
                            <div style={{fontSize: "25px", fontWeight: "800", color: "white"}}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            100 &nbsp; <i className="fas fa-star-of-david"></i>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            </Badge>
                        </div>
                        <br/>
                        <Row>
                            <Col xl="4" ></Col>
                            <Col xl="4" >
                                <Button block color="danger" >
                                    <b>Take away some </b>
                                    &nbsp;&nbsp;
                                    <i className="fas fa-star-of-david"></i>
                                </Button>
                            </Col>
                            <Col xl="4" ></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xl="4" ></Col>
                            <Col xl="3" >
                            <div style={{width: "100%", fontSize: "15px", fontWeight: "700", color: "#eee"}}>
                            <li>0x56a...ff3d got 10 <i className="fas fa-star-of-david"></i></li>
                            <li>0x74g...dy3e got 8 <i className="fas fa-star-of-david"></i></li>
                            <li>0x25f...j6hc got 5 <i className="fas fa-star-of-david"></i></li>
                            </div>
                            </Col>
                            <Col xl="1" >
                            <div style={{width: "100%", textAlign: "right", fontSize: "15px", fontWeight: "700", color: "#ffee00"}}>
                                <i className="fas fa-medal" />
                            </div>
                            <div style={{width: "100%", textAlign: "right", fontSize: "15px", fontWeight: "600", color: "#eee"}}>
                                <i className="fas fa-medal" />
                            </div>
                            <div style={{width: "100%", textAlign: "right", fontSize: "15px", fontWeight: "500", color: "#a44c25"}}>
                                <i className="fas fa-medal" />
                            </div>
                            </Col>
                            <Col xl="4" ></Col>
                        </Row>
                    </Container>
                    <div style={{padding:"15px", fontWeight: "700"}}>
                    Note: <i className="fas fa-star-of-david"/> are Monster Redemption Vouchers, and each player can only take <i className="fas fa-star-of-david"/> once per event. The number of <i className="fas fa-star-of-david"/> taken each time is random. The total number of <i className="fas fa-star-of-david"/> you have can be viewed on the Promotions page.
                    </div>
                </Row>
                <Container style={{padding:"10px"}}>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fas fa-info-circle">&nbsp;&nbsp; Next Time: Tue, 31 May 2022 16:00:00 GMT</i>
                            </div>
                        </Badge>
                        <div style={{width: "100%", padding:"10px", textAlign: "center"}}>
                        Don't want to miss out next time? Then follow us on Twitter and Telegram.
                        </div>
                    </Row>
                </Container>
                <Row>
                    <Col xl="1"></Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg4.png"/>
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg2.png"/>
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg5.png"/>
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg3.png"/>
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg1.png"/>
                    </Col>
                    <Col xl="1"></Col>
                </Row>
            </Container>
        );
    }
}

const ExtendedGiveawayDefault = withPageConfig(GiveawayDefault);

export {
    ExtendedGiveawayDefault as GiveawayDefault
};
