import React, { useEffect, useState } from 'react';

import { Spinner } from './Spinner';

const defaultConfig = {
  root: null,
  threshold: 1.0,
  rootMargin: '0px'
};

const useVisibilityHook = (options = {}, visible = false) => {
  let observer;
  const [isVisible, setIsVisible] = useState(visible);
  const [element, setElement] = useState(null);

  const forceVisible = () => {
      setIsVisible(true);
  };

  const forceCheck = () => {
      observer.unobserve(element);
      observer.observe(element);
  };

  const visibilityCallBack = ([entry]) => {
      if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
      }
  };

  useEffect(() => {
      if (visible) {
          forceVisible();
      }
  }, [visible])

  useEffect(() => {
      if (!element) {
          return;
      }
      observer = new IntersectionObserver(visibilityCallBack, {
          ...defaultConfig,
          ...options
      });
      observer.observe(element);
      return () => observer && observer.disconnect && observer.disconnect();
  }, [element, options.root, options.rootMargin, options.threshold]);

  return { setElement, isVisible, forceVisible, forceCheck };
}

const LazyLoad = (props) => {
  const { children, config = {}, style, as, forceVisible } = props;
  const CustomTag = as || 'div';
  const { setElement, isVisible } = useVisibilityHook(config, forceVisible);
  return (
      <CustomTag ref={setElement} style={style}>
          {isVisible && children}
      </CustomTag>
  );
};

export const YouTubeEmbed = ({ youtubeId }) => {
  const { setElement, isVisible } = useVisibilityHook();

  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
      ref={setElement}
    >
      { isVisible ? <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
      /> : (
        <div style={{textAlign:"center", color:"white", fontWeight:"700", paddingTop: "20.25%"}}>
          Loading ...
          <br/><br/>
          <Spinner color="white" width="100px"/>
        </div>
      )}
    </div>
  );
};