import React, { useEffect, useState } from 'react';

import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Button, Badge, Alert, ButtonGroup,
    UncontrolledModal, Modal, ModalHeader, ModalBody, ModalFooter
} from '../../../components';

import useWalletData from '../../../models/WalletModel';
import usePresaleAdoptMonster from '../../../models/PresaleModel';

import MonsterViewPixi from '../components/MonsterViewPixi';
import { Spinner } from '../components/Spinner';

import { ReactComponent as MaticTokenIconSvg } from '../../../images/matic-token-inverted-icon.svg';

import { useToggle } from '../../../hooks';

const gradeNames = ["MG_WHITE", "MG_GREEN", "MG_BLUE"];

function LevelUpPanel() {
    const { levelup } = usePresaleAdoptMonster();
    return (
        <Row className="mintBuyPanel">
            <Col xs="3"></Col>
            <Col xs="6">
            <Button color="warning" block onClick={() => levelup()}>
                <i className="fas fa-seedling"> <b>GROW UP</b></i>
            </Button>
            </Col>
            <Col xs="3"></Col>
        </Row>
    );
}

function AdoptPanel() {

    const {
        isConnected,
        isModalConnectWalletOpen, toggleModalConnectWallet,
        adoptSteps, adoptMonster
    } = useWalletData();

    const {
        requestNewMonster, wantGrade,
        price, eliteType, grade,
    } = usePresaleAdoptMonster();

    const [ isGiveUpPanelOpen, toggleGiveUpPanel ] = useToggle(false);
    const [ isAdoptPanelOpen, toggleAdoptPanel ] = useToggle(false);

    const isWaitting = adoptSteps.error === null && adoptSteps.step != 3;

    return (
        <>
        <Modal centered isOpen={isAdoptPanelOpen} className="modal-outline-warning">
            <ModalHeader tag="h6">
                <span className="text-warning">
                    { adoptSteps.title || "Adopt Your Monster" }
                </span>
            </ModalHeader>
            <ModalBody>
                <div style={{textAlign: "center"}}>
                <i className="fas fa-paw"> STEP { adoptSteps.step } </i> <br/>
                <i className="fas fa-map-marker-alt" style={ adoptSteps.step>=1 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                &nbsp;&nbsp;……&nbsp;&nbsp;
                <i className="fas fa-map-marker-alt" style={ adoptSteps.step>=2 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                &nbsp;&nbsp;……&nbsp;&nbsp;
                <i className="fas fa-map-marker-alt" style={ adoptSteps.step>=3 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                <br/>
                {
                    isWaitting ? <Spinner color="red" width="30px"/> : <br/>
                }
                </div>
                <i className="fas fa-info-circle"> { adoptSteps.message || adoptSteps.error } </i>
            </ModalBody>
            {
                isWaitting ? null : <ModalFooter><Button color="warning" onClick={()=>{toggleAdoptPanel();requestNewMonster(gradeNames[wantGrade]);}}>Close</Button></ModalFooter>
            }
        </Modal>

        <Modal centered isOpen={isGiveUpPanelOpen} toggle={toggleGiveUpPanel} className="modal-outline-danger">
            <ModalHeader tag="h6">
                <span className="text-danger">
                    Important Notes
                </span>
            </ModalHeader>
            <ModalBody>
                <div>
                Are you sure you want to give up adopting this beast? 
                If you give it up, you may never meet it again.
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    // 放弃重取
                    requestNewMonster(gradeNames[wantGrade]); // MG_WHITE MG_GREEN MG_BLUE
                }}>
                    <i className="fas fa-times-circle"> <b>Give Up</b></i>
                </Button>
                <Button color="danger" onClick={() => {
                    if(isConnected) {
                        toggleAdoptPanel();
                        adoptMonster();
                    } else {
                        toggleModalConnectWallet();
                    }
                }}>
                    <i className="fas fa-shopping-cart"> <b>Adopt for </b></i>
                    &nbsp;&nbsp;
                    <MaticTokenIconSvg width={20} height={20}/>
                    &nbsp;&nbsp;
                    {price}
                </Button>
            </ModalFooter>
        </Modal>

        <Row className="mintBuyPanel">
            <Col xl="5">
                <Button color="secondary" block onClick={() => {
                    // 放弃重取
                    if ((eliteType>0)||(grade>2)) {
                        toggleGiveUpPanel();
                    } else {
                        requestNewMonster(gradeNames[wantGrade]); // MG_WHITE MG_GREEN MG_BLUE
                    }
                }}>
                    <i className="fas fa-times-circle"> <b>Give Up</b></i>
                </Button>
            </Col>
            <Col xl="2">
            </Col>
            <Col xl="5">
                <Button color="danger" block onClick={() => {
                    if(isConnected) {
                        toggleAdoptPanel();
                        adoptMonster();
                    } else {
                        toggleModalConnectWallet();
                    }
                }}>
                    <i className="fas fa-shopping-cart"> <b>Adopt for </b></i>
                    &nbsp;&nbsp;
                    <MaticTokenIconSvg width={20} height={20}/>
                    &nbsp;&nbsp;
                    {price}
                </Button>
            </Col>
        </Row>
        </>
    );
}

function MintDefault(props) {
    const {
        requestReferrerCode,
        requestNewMonster, level, wantGrade,
        setWantGrade, avatarData, medalFlags
    } = usePresaleAdoptMonster();

    const { id } = props.match.params;

    useEffect(() => {
        requestReferrerCode(id);
        if (!avatarData) requestNewMonster(gradeNames[wantGrade]);
    }, []);

    return (
        <Container>
            <Row className="align-items-center justify-content-xl-between">
                <h4 className="home-titlecenterfont">
                    Click the Button to Grow <br/>
                    Current Level: {level}
                </h4>
            </Row>
            <Row className="align-items-center justify-content-xl-between">
                <Container className="ani-panel">
                    <Row>
                        <Col className="accordion" style={{textAlign: "center"}}>
                            <Row>
                                <Col></Col>
                                <Col>
                                <ButtonGroup style={{width:"100%"}}>
                                    <Button
                                    color={wantGrade===0?"light":"dark"}
                                    onClick={() => setWantGrade(0)} >
                                        <img src="/resource/flags/flag0.png" style={{height:"35px"}} /> <b>General</b>
                                    </Button>
                                    <Button
                                    color={wantGrade===1?"light":"dark"}
                                    onClick={() => setWantGrade(1)} >
                                        <img src="/resource/flags/flag1.png" style={{height:"35px"}} /> <b> Not Bad</b>
                                    </Button>
                                    <Button
                                    color={wantGrade===2?"light":"dark"}
                                    onClick={() => setWantGrade(2)} >
                                        <img src="/resource/flags/flag2.png" style={{height:"35px"}} /> <b>Good</b>
                                    </Button>
                                </ButtonGroup>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{height:"8px"}} />
                            <MonsterViewPixi avatarData={avatarData} medalFlags={medalFlags}/>
                            <Container>
                                {
                                    level>=10 ? <AdoptPanel /> : <LevelUpPanel />
                                }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="align-items-center justify-content-xl-between" style={{paddingLeft:"60px", paddingRight:"60px", paddingTop:"10px"}}>
            <b>Note: You can choose your own monsters only in the pre-sale, and only those 10,000 monsters will be sold for Generation 0. Each Generation 0 monster is level 10 (the highest level).</b>
            </Row>
            <br/>
            <Container style={{padding:"10px"}}>
                <Row className="align-items-center">
                    <Badge color="dark" pill  style={{width: "100%"}}>
                        <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                        <i className="fas fa-exclamation-triangle"> Something Important</i>
                        </div>
                    </Badge>
                </Row>
            </Container>
            <Container className="accordion">
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont">
                        Monster's Grades
                    </h4>
                </Row>
                <br/>
                <Row>
                    <Col xl="1"></Col>
                    <Col xl="2">
                        <Alert color="dark" style={{textAlign:"center"}} >
                            <img src="/resource/flags/flag0.png"/><br/>
                            General
                        </Alert>
                    </Col>
                    <Col xl="2">
                        <Alert color="success" style={{textAlign:"center"}} >
                            <img src="/resource/flags/flag1.png"/><br/>
                            Not Bad
                        </Alert>
                    </Col>
                    <Col xl="2">
                        <Alert color="primary" style={{textAlign:"center"}} >
                            <img src="/resource/flags/flag2.png"/><br/>
                            Good
                        </Alert>
                    </Col>
                    <Col xl="2">
                        <Alert color="warning" style={{textAlign:"center"}} >
                            <img src="/resource/flags/flag3.png"/><br/>
                            Excellent
                        </Alert>
                    </Col>
                    <Col xl="2">
                        <Alert color="danger" style={{textAlign:"center"}} >
                            <img src="/resource/flags/flag4.png"/><br/>
                            Perfect
                        </Alert>
                    </Col>
                    <Col xl="1"></Col>
                </Row>
            </Container>
            <Row className="align-items-center justify-content-xl-between" style={{paddingLeft:"60px", paddingRight:"60px", paddingTop:"10px"}}>
            <b>Note: The highest grade a monster can reach in pre-sale is Excellent. And Excellent is the necessary foundation for breeding Perfect monsters.</b>
            </Row>
            <br/>
            <Container className="accordion">
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont">
                        Rare Achievements for Monsters
                    </h4>
                </Row>
                <br/>
                <Row>
                    <Col xl="1"></Col>
                    <Col xl="5">
                        <Alert color="warning" >
                            <Row>
                            <Col xs="3"><img src="/resource/flags/medalSilver.png"/></Col>
                            <Col xs="9">
                            A <b>silver medal</b> indicates that the monster's rarity is higher than the average score of the same grade.
                            </Col>
                            </Row>
                        </Alert>
                    </Col>
                    <Col xl="5">
                        <Alert color="danger" >
                            <Row>
                            <Col xs="3"><img src="/resource/flags/medalGold.png"/></Col>
                            <Col xs="9">
                            A <b>gold medal</b> indicates that the monster's rarity is well above the average score of the same grade.
                            </Col>
                            </Row>
                        </Alert>
                    </Col>
                    <Col xl="1"></Col>
                </Row>
            </Container>
        </Container>
    );
}

const ExtendedMintDefault = withPageConfig(MintDefault);

export {
    ExtendedMintDefault as MintDefault
};