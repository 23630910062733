// FAQDefault
import React from 'react';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Badge,
} from '../../../components';

import FAQ from './../Home/components/FAQ';

class FAQDefault extends React.Component {

    render() {
        return (
            <Container>
                <Container>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fas fa-question-circle"> FAQ</i>
                            </div>
                        </Badge>
                    </Row>
                </Container>

                <br/>

                <FAQ />
            </Container>
        );
    }
}

const ExtendedFAQDefault = withPageConfig(FAQDefault);

export {
    ExtendedFAQDefault as FAQDefault
};