import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#ffce3f', '#ee4645', '#fda028', '#6bc44f', '#29cfd0', '#ff8a3c', '#119fec', '#965bbb'];

const data = [
  { name: 'Private', value: 5_000_000, vesting: '5% at token generated, 3 month lock up, then linear vesting 12 months' },
  { name: 'Staking in preonline', value: 2_500_000, vesting: 'Linear vesting 2 months' },
  { name: 'Game Incentives', value: 49_000_000, vesting: 'Starting unlock when games online, then linear vesting 4 years' },
  { name: 'Team', value: 10_000_000, vesting: 'Fully lock 6 months, then linear vesting 4 years' },
  { name: 'Advisor', value: 3_000_000, vesting: 'Fully lock 6 months, then linear vesting 4 years' },
  { name: 'Marketing& Partnership', value: 10_000_000, vesting: 'Unlock 5% 7 days after TGE, then Linear vesting 2 years' },
  { name: 'Eco-Fund', value: 8_000_000, vesting: 'Unlock 5% at TGE, one month after 5%, then linear vesting for 12 months' },
  { name: 'Reserves', value: 12_500_000, vesting: 'Reserves for liquidity' },
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const dsign = (cos >= 0 ? 1 : -1);
  const ex = mx + dsign * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  //    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
  //      {payload.name}
  //    </text>

  return (
    <g>
      <foreignObject x={cx-80} y={cy-(payload.name.length<11?18:36)} width="160" height="100">
        <div style={{textAlign:"center", fontSize:"24px", fontWeight:"800", color:"#fff"}} xmlns="http://www.w3.org/1999/xhtml">{payload.name}</div>
      </foreignObject>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <foreignObject x={ex + dsign * 12 + (cos >= 0 ? 0 : -188)} y={ey} fill="#fff" width="300" height="150">
        <p xmlns="http://www.w3.org/1999/xhtml">{payload.vesting}</p>
      </foreignObject>
      <text x={ex + dsign * 12} y={ey - 28} dy={18} textAnchor={textAnchor} fill="#fff" fontWeight={900}>
        {`Rate ${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export default class CustomActivePieChart extends PureComponent {
  state = {
    activeIndex: 2,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    return (
      <ResponsiveContainer width='100%' aspect={6.0/3.0}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="30%"
            outerRadius="70%"
            dataKey="value"
            onMouseEnter={this.onPieEnter}
            strokeWidth={2}
          >
            {
                data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
// 100, 180
// paddingAngle={5}