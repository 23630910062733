
// ScoreEvaluation

function getAvatarScore(avatarData) {
    let tmpID = 0;
    let _score = 100; // body
    // hand, foot, leg
    if (avatarData.handL.id === avatarData.handR.id) _score += 50;
    if (avatarData.footL.id === avatarData.footR.id) _score += 50;
    if (avatarData.legL.id === avatarData.legR.id) _score += 50;
    // nose
    tmpID = parseInt(avatarData.nose.id);
    if (tmpID < 0) tmpID = 0;
    _score += tmpID * 30;
    // mouth
    tmpID = parseInt(avatarData.mouth.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID > 1) {
        if (tmpID < 50) _score += tmpID * 15;
        else _score += tmpID * 10 + 200;
    }
    // eyes
    tmpID = parseInt(avatarData.eyes.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID > 2) _score += tmpID * 15;
    // ears
    tmpID = parseInt(avatarData.ears.id);
    if (tmpID < 0) tmpID = 0;
    _score += tmpID * 30;
    // beard
    tmpID = parseInt(avatarData.beard.id);
    if (tmpID < 0) tmpID = 0;
    _score += tmpID * 30;
    // hair
    tmpID = parseInt(avatarData.hair.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID < 50) _score += tmpID * 30;
    else _score += tmpID * 10;
    // frontHornL
    tmpID = parseInt(avatarData.frontHornL.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID < 50) _score += tmpID * 15;
    else _score += tmpID * 10;
    // frontHornR
    tmpID = parseInt(avatarData.frontHornR.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID < 50) _score += tmpID * 15;
    else _score += tmpID * 10;
    // backHornL
    tmpID = parseInt(avatarData.backHornL.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID < 50) _score += tmpID * 15;
    else _score += tmpID * 10;
    // backHornR
    tmpID = parseInt(avatarData.backHornR.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID < 50) _score += tmpID * 15;
    else _score += tmpID * 10;
    // wing
    tmpID = parseInt(avatarData.wing.id);
    if (tmpID < 0) tmpID = 0;
    if (tmpID < 50) _score += tmpID * 30;
    else _score += tmpID * 20;
    // forehead
    tmpID = parseInt(avatarData.forehead.id);
    if (tmpID < 0) tmpID = 0;
    _score += tmpID * 10;
    // glasses
    tmpID = parseInt(avatarData.glasses.id);
    if (tmpID < 0) tmpID = 0;
    _score += tmpID * 20;
    // pet
    tmpID = parseInt(avatarData.pet.id);
    if (tmpID < 0) tmpID = 0;
    _score += tmpID * 30;

    return _score;
}

export {
    getAvatarScore
};
