import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Button, Alert, Badge, 
} from '../../../components';
import { scrollToElement } from '../../components';

import FAQ from './components/FAQ';
import HomeWhyBuy from './components/HomeWhyBuy';
import CustomActivePieChart from './components/CustomActivePieChart';

import { ReactComponent as RoadmapSVG } from '../../../images/roadmap.svg';

import { YouTubeEmbed } from "../components/YouTubeEmbed";

class HomeDefault extends React.Component {
    static propTypes = {
        pageConfig: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.currentPathId = null;
    }

    componentDidMount() {
        const { pageConfig } = this.props;
        
        pageConfig.setElementsVisibility({
            sidebarHidden: true,
            homeHidden: false
        });
    }

    componentWillUnmount() {
        const { pageConfig } = this.props;

        pageConfig.setElementsVisibility({
            sidebarHidden: false,
            homeHidden: true
        });
    }

    componentDidUpdate(prevProps) {
        const targetPath = this.props.targetPath;
        if (targetPath && (!this.currentPathId || prevProps.targetPath !== targetPath)) {
            this.currentPathId = targetPath;
            scrollToElement(targetPath, 100);
        }
    }

    // background:"#014220", 
    render() {
        return (
            <>
            <Container>
                <Row className="align-items-center justify-content-xl-between">
                    <Col xl="4">
                        <div className="home-slogan-min" style={{width: "379px"}}>
                            COLLECT.BATTLE.TEAM.STAKE.<br/>
                            CULTIVATE.ADVENTURE.EARN.
                        </div>
                    </Col>
                    <Col xl="5">
                        <div style={{height: "10px", width: "475px"}}></div>
                    </Col>
                    <Col xl="3">
                        <div className="home-slogan-min" style={{width: "285px"}}>
                            RANKING.TREASURE.<br/>
                            REPRODUCTION.LUCK.
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="imgCol">
                        <img src="/img/game4.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/>
                        <img src="/img/game3.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/><br/>
                        <img src="/img/game0.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/><br/>
                        <img src="/img/game2.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/>
                        <img src="/img/game1.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <img src="/img/game5.png"/>
                    </Col>
                </Row>
            </Container>
            <Container>
                <p className="mb-5 mt-3">
                It's a world where all the NFTs are created by you.
                Among the thousands of possibilities, find what you like and what is valuable.
                Now, 
                <Link to="/mint" className="text-primary-light"> create </Link>
                your own, unique monster for free.
                </p>

                <Container className="accordion">
                    <YouTubeEmbed youtubeId="zL6DwocZHn8" />
                </Container>

                <br/>

                <Container>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fab fa-teamspeak"> Why should I buy a Monster?</i>
                            </div>
                        </Badge>
                    </Row>
                </Container>

                <br/>

                <HomeWhyBuy />

                <br/>

                <Container>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fas fa-map-signs"> ROADMAP</i>
                            </div>
                        </Badge>
                    </Row>
                </Container>

                <br/>

                <section className="mb-5">
                    <h4 className="home-titlecenterfont">
                        What we are working on
                    </h4>
                    <Container style={{position: "relative"}}>
                    <Row>
                        <RoadmapSVG/>
                        <div className="roadmapBox" style={{top:"0px", left:"110px", borderColor:"#fff", background:"#ffa101"}}>
                            <div style={{fontSize:"24px", paddingLeft:"10px"}}>
                                <i className="fas fa-paw"> Q2 2022</i>
                            </div>
                            <li>NFT Launches</li>
                            <li>Launch of pre-sale partners program</li>
                        </div>
                        <div className="roadmapBox" style={{top:"10px", left:"530px"}}>
                            <div style={{fontSize:"24px", paddingLeft:"10px"}}>
                                <i className="fas fa-paw"> Q3 2022</i>
                            </div>
                            <li>MCT Token Launch</li>
                            <li>Launch of Treasure Forest Map</li>
                            <li>Launch of monster competition with ranking incentives</li>
                        </div>
                        <div className="roadmapBox" style={{top:"220px", left:"630px"}}>
                            <div style={{fontSize:"24px", paddingLeft:"10px"}}>
                                <i className="fas fa-paw"> Q4 2022</i>
                            </div>
                            <li>Marketplace</li>
                            <li>MNT Token Launch</li>
                            <li>Open monster farming (hatching, growing, adventuring, fighting, breeding)</li>
                        </div>
                        <div className="roadmapBox" style={{top:"215px", left:"210px"}}>
                            <div style={{fontSize:"24px", paddingLeft:"10px"}}>
                                <i className="fas fa-paw"> Q1 2023</i>
                            </div>
                            <li>Launch of first version of the game (roulette, tasks, team combat and rankings)</li>
                            <li>Adventure Forest map</li>
                            <li>Launch of the prize pool</li>
                        </div>
                        <div className="roadmapBox" style={{top:"450px", left:"375px"}}>
                            CHIRPY REVERIE IS APPROACHING...
                        </div>
                    </Row>
                    </Container>
                </section>

                <Container>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fas fa-coins"> TOKENOMICS</i>
                            </div>
                        </Badge>
                    </Row>
                </Container>

                <br/>

                <section className="mb-5">
                    <ul className="pl-3">
                    The Community token of the monster kings is MCT.
                    Token Total Supply: 100,000,000 MCT.
                    For more information about tokens, please refer to the white paper.
                    </ul>
                    <h4 className="home-titlecenterfont">
                        Allocation
                    </h4>
                    <div style={{background: "rgba(255,255,255,0.15)", borderStyle:"dashed", borderWidth:"5px", borderColor:"#006430", borderRadius:"56px", marginBottom:"10px"}}>
                        <CustomActivePieChart/>
                    </div>
                    <div style={{paddingLeft:"70px", paddingRight:"70px", width:"100%"}}>
                        <li>
                            <div className="boldWords" style={{background:"#ffce3f"}}>Private</div>
                            &nbsp;- 5% at token generated, 3 month lock up, then linear vesting 12 months.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#ee4645"}}>Staking in preonline</div>
                            &nbsp;- Linear vesting 2 months.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#fda028"}}>Game Incentives</div>
                            &nbsp;- Starting unlock when games online, then linear vesting 4 years.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#6bc44f"}}>Team</div>
                            &nbsp;- Fully lock 6 months, then linear vesting 4 years.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#29cfd0"}}>Advisor</div>
                            &nbsp;- Fully lock 6 months, then linear vesting 4 years.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#ff8a3c"}}>Marketing and Partnership</div>
                            &nbsp;- Unlock 5% 7 days after TGE, then Linear vesting 2 years.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#119fec"}}>Eco-Fund</div>
                            &nbsp;- Unlock 5% at TGE, one month after 5%, then linear vesting for 12 months.
                        </li>
                        <li>
                            <div className="boldWords" style={{background:"#965bbb"}}>Reserves</div>
                            &nbsp;- Reserves for liquidity.
                        </li>
                    </div>
                </section>

                <section id="faq"></section>
                <Container>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fas fa-question-circle"> FAQ</i>
                            </div>
                        </Badge>
                    </Row>
                </Container>

                <br/>

                <FAQ />

            </Container>
            </>
        );
    }
}

const ExtendedHomeDefault = withPageConfig(HomeDefault);

export {
    ExtendedHomeDefault as HomeDefault
};
