// PromotionsDefault
import React, { useEffect, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Badge, Alert, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from '../../../components';

import { Spinner } from '../components/Spinner';

import useSimpleWheelData from "../../../models/SimpleWheelModel";
import WheelComponent from "../components/WheelComponent";

import copy from 'copy-to-clipboard';
 
import useWalletData from '../../../models/WalletModel';

import { useToggle } from '../../../hooks';

// 未登录
function NoLoginPanel() {
    const {
        toggleModalConnectWallet,
    } = useWalletData();

    return (
        <div>
            <Container className="infoBox">
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont" style={{color:"#000"}}>
                        <i className="fas fa-exclamation-circle"> You Are Not Logged In</i>
                    </h4>
                </Row>
                <Row>
                <div>
                    <li><b>
                        Before you can start the promotion, you have to log in to your account.
                    </b></li>
                    <li><b>
                        Once logged in, you will be able to view your current promotion results (e.g. the number of Monster redemption vouchers you have).
                    </b></li>
                </div>
                </Row>
                <Row style={{height:"20px"}}></Row>
                <Row>
                    <Button block color="danger" onClick={() => {
                        toggleModalConnectWallet();
                    }}>
                        <i className="fas fa-user"> <b> Sign in now</b></i>
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

// 没有拥有一只怪物
function NoMonsterPanel() {
    return (
        <div>
            <Container className="infoBox">
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont" style={{color:"#000"}}>
                        <i className="fas fa-exclamation-circle"> You Haven't Adopted a Monster Yet</i>
                    </h4>
                </Row>
                <Row>
                <div>
                    <li><b>
                    You need to adopt at least one monster before you can start the promotion.
                    </b></li>
                </div>
                </Row>
                <Row style={{height:"20px"}}></Row>
                <Row>
                    <Button block color="danger" tag={ Link } to="/mint">
                        <i className="fas fa-medal">  <b>Adopt Now</b></i>
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

// 登录后简化信息显示
function InfoShowPanelSimple() {
    const {
        isConnected,
        redemptionVouchers, getRedemptionVouchers,
        getOrCreateRefLinks, refcodeLink
    } = useWalletData();

    const refLinkText = refcodeLink;

    useEffect(() => {
        getOrCreateRefLinks();
    }, []);

    useEffect(() => {
        getRedemptionVouchers();
    }, [isConnected]);

    return (
        <div>
            <Container style={{padding:"20px"}}>
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont" style={{color:"#fff"}}>
                        <b>Your Monster Redemption Vouchers: </b>
                        &nbsp;
                        <Badge color="light" pill>
                            <div style={{fontSize:"25px", fontWeight:"800", margin:"8px 8px"}}>
                            &nbsp;<i className="fas fa-star-of-david" style={{color:"#ff0000"}}>
                            &nbsp;{Math.round(redemptionVouchers)}</i> &nbsp;
                            </div>
                        </Badge>
                        &nbsp;&nbsp;
                        <Button color="warning" onClick={getRedemptionVouchers}>
                            <i className="fas fa-sync-alt"></i>
                        </Button>
                    </h4>
                </Row>
            </Container>
        </div>
    );
}

// 登录后推广信息显示
function InfoShowPanel() {
    const {
        isConnected,
        redemptionVouchers, getRedemptionVouchers,
        getOrCreateRefLinks, refcodeLink
    } = useWalletData();

    const refLinkText = refcodeLink;

    useEffect(() => {
        getOrCreateRefLinks();
    }, []);

    useEffect(() => {
        getRedemptionVouchers();
    }, [isConnected]);

    return (
        <div>
            <Container className="dataShowBox" style={{background:"#00000044", borderColor:"#fff"}}>
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont" style={{color:"#fff"}}>
                        <b>Your Monster Redemption Vouchers: </b>
                        &nbsp;
                        <Badge color="light" pill>
                            <div style={{fontSize:"25px", fontWeight:"800", margin:"8px 8px"}}>
                            &nbsp;<i className="fas fa-star-of-david" style={{color:"#ff0000"}}>
                            &nbsp;{Math.round(redemptionVouchers)}</i> &nbsp;
                            </div>
                        </Badge>
                        &nbsp;&nbsp;
                        <Button color="warning" onClick={getRedemptionVouchers}>
                            <i className="fas fa-sync-alt"></i>
                        </Button>
                    </h4>
                </Row>
                <hr style={{width:"100%", borderStyle:"dashed", borderColor:"#fff"}} />
                <Row className="align-items-center justify-content-xl-between" style={{marginTop:"15px"}}>
                    <h5 className="home-titlecenterfont" style={{color:"#ddd"}}>
                        <b>Refer a friend: </b>
                        &nbsp;
                        <Badge color="dark" pill>
                            <div style={{fontSize:"20px", color:"#ccc", fontWeight:"800", margin:"8px 8px"}}>
                            &nbsp; {refLinkText} &nbsp;
                            </div>
                        </Badge>
                        &nbsp;&nbsp;
                        <Button color="dark" onClick={() => {
                            copy(refLinkText);
                            alert("Copied!");
                        }}>
                            <i className="fas fa-copy"></i>
                        </Button>
                    </h5>
                    <h6 className="home-titlecenterfont" style={{color:"#ddd"}}>
                    <i className="fas fa-info-circle"> If your friend buys a monster, you will both receive some monster redemption vouchers.</i>
                    </h6>
                </Row>
            </Container>
        </div>
    );
}

// 宠物蛋购买面板
function EggBuyPanel() {
    const { redeemMonster, redemptionVouchers } = useWalletData();
    const [ isInsufficientInfoPanelOpen, toggleInsufficientInfoPanel ] = useToggle(false);
    const [ isAdoptPanelOpen, toggleAdoptPanel ] = useToggle(false);
    const {
        adoptSteps
    } = useWalletData();

    const isWaitting = adoptSteps.error === null && adoptSteps.step != 3;

    return (
        <>
        <Modal centered isOpen={isAdoptPanelOpen} className="modal-outline-warning">
            <ModalHeader tag="h6">
                <span className="text-warning">
                    { adoptSteps.title || "Adopt Your Monster" }
                </span>
            </ModalHeader>
            <ModalBody>
                <div style={{textAlign: "center"}}>
                <i className="fas fa-paw"> STEP { adoptSteps.step } </i> <br/>
                <i className="fas fa-map-marker-alt" style={ adoptSteps.step>=1 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                &nbsp;&nbsp;……&nbsp;&nbsp;
                <i className="fas fa-map-marker-alt" style={ adoptSteps.step>=2 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                &nbsp;&nbsp;……&nbsp;&nbsp;
                <i className="fas fa-map-marker-alt" style={ adoptSteps.step>=3 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                <br/>
                {
                    isWaitting ? <Spinner color="red" width="30px"/> : <br/>
                }
                </div>
                <i className="fas fa-info-circle"> { adoptSteps.message || adoptSteps.error } </i>
            </ModalBody>
            {
                isWaitting ? null : <ModalFooter><Button color="warning" onClick={()=>{toggleAdoptPanel();}}>Close</Button></ModalFooter>
            }
        </Modal>
        <Modal centered isOpen={isInsufficientInfoPanelOpen} className="modal-outline-warning">
            <ModalHeader tag="h6">
                <span className="text-warning">
                Insufficient Vouchers
                </span>
            </ModalHeader>
            <ModalBody>
            <i className="fas fa-exclamation-triangle">
            &nbsp;You don't have enough monster redemption vouchers.
            </i>
            </ModalBody>
            <ModalFooter>
                <Button color="warning" onClick={()=>{toggleInsufficientInfoPanel();}}>Close</Button>
            </ModalFooter>
        </Modal>

        <Container className="accordion" style={{textAlign:"center", background:"rgba(255,255,255,0.3)"}}>
            <Row className="align-items-center justify-content-xl-between">
                <Col xl="4" style={{paddingTop:"19px"}}>
                    <img src="/img/egg1.png" style={{background:"#d6d6d6", padding:"20px", borderColor:"#006430", borderStyle:"dashed", borderRadius:"35px", marginBottom:"8px"}}/>
                    <Button color="danger" onClick={() => {redemptionVouchers<140?toggleInsufficientInfoPanel():redeemMonster("MG_WHITE");toggleAdoptPanel();} }>
                        <i className="fas fa-shopping-basket"> <b>Adopt it with </b></i>
                        &nbsp;&nbsp;
                        <i className="fas fa-star-of-david"> <b>140</b> </i>
                    </Button>
                </Col>
                <Col xl="4" style={{paddingTop:"6px"}}>
                    <img src="/img/egg2.png" style={{background:"#6bde8e", padding:"20px", borderColor:"#006430", borderStyle:"dashed", borderRadius:"35px", marginBottom:"8px"}}/>
                    <Button color="danger" onClick={() => {redemptionVouchers<400?toggleInsufficientInfoPanel():redeemMonster("MG_GREEN");toggleAdoptPanel();} }>
                        <i className="fas fa-shopping-basket"> <b>Adopt it with </b></i>
                        &nbsp;&nbsp;
                        <i className="fas fa-star-of-david"> <b>400</b> </i>
                    </Button>
                </Col>
                <Col xl="4">
                    <img src="/img/egg3.png" style={{background:"#699cdd", padding:"20px", borderColor:"#006430", borderStyle:"dashed", borderRadius:"35px", marginBottom:"8px"}}/>
                    <Button color="danger" onClick={() => {redemptionVouchers<900?toggleInsufficientInfoPanel():redeemMonster("MG_BLUE");toggleAdoptPanel();} }>
                        <i className="fas fa-shopping-basket"> <b>Adopt it with </b></i>
                        &nbsp;&nbsp;
                        <i className="fas fa-star-of-david"> <b>900</b> </i>
                    </Button>
                </Col>
            </Row>
            <br/>
            <div className="boldWords" style={{background:"#128444aa", color:"#0007", paddingTop:"15px", paddingBottom:"15px", borderRadius: "30px", paddingRight: "60px", paddingLeft: "60px"}}>
                You can find all the monsters you have on the 
                <Link to="/dashboard" className="text-primary-light"> Dashboard </Link>
                .
            </div>
        </Container>
        </>
    );
}

const segments = [
    "5 Coupon",
    "10 Coupons",
    "15 Coupons",
    "25 Coupons",
    "50 Coupons",
    "500 Coupons"
  ];

  function getRandSegment() {
      console.log("++++++ getRandSegment ++++++");
      return segments[Math.round(Math.random() * (segments.length-1))];
  }
  
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    //"#EC3F3F",
    //"#FF9000"
  ];

  const onFinished = (winner) => {
    //console.log("--->", winner);
    //alert(winner);
  };

  function WheelButton() {
      const { redemptionVouchers, getRedemptionVouchers, } = useWalletData();
      const [ isInsufficientInfoPanelOpen, toggleInsufficientInfoPanel ] = useToggle(false);
      const { chestStatus, resetChest, buyOrOpenTreasureChest } = useSimpleWheelData();

      switch (chestStatus) {
        case 300:
            return (
                <>
                <Button block color="danger" onClick={() => { buyOrOpenTreasureChest(); }}>
                    <i className="fas fa-magic"> <b> Open </b></i>
                </Button>
                </>
              );
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
            return (
                <>
                <Button block color="danger" onClick={() => { resetChest();getRedemptionVouchers(); }}>
                    <i className="fas fa-magic"> <b> OK </b></i>
                </Button>
                </>
              );
      }

      return (
        <>
        <Modal centered isOpen={isInsufficientInfoPanelOpen} className="modal-outline-warning">
            <ModalHeader tag="h6">
                <span className="text-warning">
                Insufficient Vouchers
                </span>
            </ModalHeader>
            <ModalBody>
            <i className="fas fa-exclamation-triangle">
            &nbsp;You don't have enough monster redemption vouchers.
            </i>
            </ModalBody>
            <ModalFooter>
                <Button color="warning" onClick={()=>{toggleInsufficientInfoPanel();}}>Close</Button>
            </ModalFooter>
        </Modal>
        <Button block color="danger" onClick={() => {
            redemptionVouchers<10 ? toggleInsufficientInfoPanel() : buyOrOpenTreasureChest();
        }}>
            <i className="fas fa-magic"> <b> Try once with </b></i>
            &nbsp;&nbsp;
            <i className="fas fa-star-of-david"> <b>10</b> </i>
        </Button>
        </>
      );
  }

  function WheelPanel() {
    const { isWaitting, info } = useSimpleWheelData();
    
    return (
        <>
        <Container style={{padding:"10px"}}>
            <Row className="align-items-center">
                <Badge color="dark" pill  style={{width: "100%"}}>
                    <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                    <i className="fas fa-hand-sparkles"> Good Luck</i>
                    </div>
                </Badge>
            </Row>
        </Container>
        <Container className="accordion" style={{paddingLeft:"60px", paddingRight:"60px", background:"rgba(255,255,255,0.3)"}}>
            <Row className="align-items-center justify-content-xl-between">
                <h4 className="home-titlecenterfont">
                {info}
                </h4>
            </Row>
            <br/>
            <Row className="align-items-center justify-content-xl-between">
                <Col xs="1"></Col>
                <Col xs="10">
                    <WheelComponent
                        segments={segments}
                        segColors={segColors}
                        width="100%"
                        onFinished={(winner) => onFinished(winner)}
                        primaryColor="black"
                        contrastColor="white"
                        grayColor="#444"
                    />
                </Col>
                <Col xs="1"></Col>
            </Row>
            <br/>
            <Row>
                <Col xs="3"></Col>
                <Col xs="6">
                    {
                        isWaitting ? <Button block color="danger" disabled><i className="fas fa-magic"> <b> Please waiting </b></i></Button> : <WheelButton />
                    }
                </Col>
                <Col xs="3"></Col>
            </Row>
        </Container>
        <br/>
        </>
    );
  }

  function PromotionsDefault() {
    const {isConnected, getMonster, monstersNum} = useWalletData();
    
    useEffect(() => {
        if(isConnected) { getMonster(); }
    }, [isConnected]);

    // { (isConnected&&(monstersNum<1)) ? <NoMonsterPanel />:null }
    return (
        <Container>
            <Row className="align-items-center justify-content-xl-between">
                <h4 className="home-titlecenterfont">
                Recommend These Cute Monsters to Your Friends
                </h4>
            </Row>

            { (isConnected&&(monstersNum<1)) ? <null />:null }

            { isConnected ? <EggBuyPanel />:null }
            { isConnected ? <InfoShowPanelSimple />:null }
            { isConnected ? <WheelPanel />:null }

            <Container style={{padding:"10px"}}>
                <Row className="align-items-center">
                    <Badge color="dark" pill  style={{width: "100%"}}>
                        <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                        <i className="fas fa-info-circle"> Promotion Rules</i>
                        </div>
                    </Badge>
                </Row>
            </Container>
            <Container className="accordion" style={{paddingLeft:"60px", paddingRight:"60px", background:"rgba(255,255,255,0.7)"}}>
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont">
                        Refer More Friends to Buy<br/>
                        Earn More Monster Redemption Vouchers
                    </h4>
                </Row>
                <br/>
                <Row>
                <div style={{paddingLeft:"70px", paddingRight:"70px", width:"100%"}}>
                        <div className="boldWords" style={{background:"#ffce3f"}}>1</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;Anyone who has adopted a monster will have a promotional link.
                        </b>
                        <br/>
                        <div className="boldWords" style={{background:"#ee4645"}}>2</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;The first step of promotion is very simple, you just need to send your promotion link to your friends.
                        </b>
                        <br/>
                        <div className="boldWords" style={{background:"#6bc44f"}}>3</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;When a friend adopts a monster using your promotional link, then you will both receive the same amount of rewards.
                        </b>
                        <br/>
                        <div className="boldWords" style={{background:"#29cfd0"}}>4</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;The reward for a successful promotion is some monster redemption vouchers. Reward details are as follows.
                        </b>
                        <br/>
                        <div className="boldWords" style={{background:"#ff8a3c"}}>5</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;Monster redemption vouchers can be used to exchange for level 10 monsters of Generation 1.
                        </b>
                        <br/>
                        <div className="boldWords" style={{background:"#119fec"}}>6</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;First generation monsters can also participate in all games and activities.
                        </b>
                        <br/>
                        <div className="boldWords" style={{background:"#965bbb"}}>7</div>
                        <b>
                        &nbsp;&nbsp;&nbsp;&nbsp;You can also use monster redemption vouchers to buy a treasure chest and try your luck.
                        </b>
                </div>
                </Row>
                <br/>
                <Container className="accordion" style={{paddingLeft:"60px", paddingRight:"60px", background:"rgba(255,255,255,0.7)"}}>
                <Row>
                    <Col></Col>
                    <Col><div style={{fontSize:"22px", fontWeight:"700", textAlign:"center", color:"#999", paddingTop:"20px"}}>Normal</div></Col>
                    <Col><div style={{textAlign:"center"}}><img src="/resource/flags/medalSilver.png"/></div></Col>
                    <Col><div style={{textAlign:"center"}}><img src="/resource/flags/medalGold.png"/></div></Col>
                </Row>
                <hr style={{width:"100%", borderStyle:"dashed", borderColor:"#000"}} />
                <Row>
                    <Col><div style={{textAlign:"center"}}><img src="/resource/flags/flag0.png" style={{height:"56px", width:"44px"}}/></div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;10</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;11</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;15</div></Col>
                </Row>
                <hr style={{width:"100%", borderStyle:"dashed", borderColor:"#000"}} />
                <Row>
                    <Col><div style={{textAlign:"center"}}><img src="/resource/flags/flag1.png" style={{height:"56px", width:"44px"}}/></div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;20</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;22</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;30</div></Col>
                </Row>
                <hr style={{width:"100%", borderStyle:"dashed", borderColor:"#000"}} />
                <Row>
                    <Col><div style={{textAlign:"center"}}><img src="/resource/flags/flag2.png" style={{height:"56px", width:"44px"}}/></div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;40</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;44</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;60</div></Col>
                </Row>
                <hr style={{width:"100%", borderStyle:"dashed", borderColor:"#000"}} />
                <Row>
                    <Col><div style={{textAlign:"center"}}><img src="/resource/flags/flag3.png" style={{height:"56px", width:"44px"}}/></div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;80</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;88</div></Col>
                    <Col><div className='price-text'><i className="fas fa-star-of-david" />&nbsp;120</div></Col>
                </Row>
                </Container>
            </Container>

            { isConnected ? null:<NoLoginPanel /> }
            
        </Container>
    );
}

const ExtendedPromotionsDefault = withPageConfig(PromotionsDefault);

export {
    ExtendedPromotionsDefault as PromotionsDefault
};