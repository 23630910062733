import React from 'react';

import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Button, Badge, Alert,
} from '../../../components';

import { YouTubeEmbed } from "../components/YouTubeEmbed";

function MintComingDefault(props) {

    return (
        <>
        <Container>
            <Row className="align-items-center justify-content-xl-between">
                    <div style={{width: "100%", fontSize: "60px", fontWeight: "800", textAlign: "center", color: "#004400"}}>
                    Coming Soon
                    </div>
            </Row>

            <br/>

            <Row className="align-items-center justify-content-xl-between" style={{paddingLeft:"60px", paddingRight:"60px", paddingTop:"10px"}}>
            <i className="fas fa-paw">&nbsp;&nbsp;Please look forward to the upcoming sale of Generation 0 monsters.</i>
            </Row>

            <br/>

            <Container className="accordion">
                <YouTubeEmbed youtubeId="HDGtswZ07T0" />
            </Container>
        </Container>
        <Container>
        <Row className="align-items-center justify-content-xl-between">
            <div style={{width: "100%", fontSize: "30px", fontWeight: "800", textAlign: "center", color: "#004400"}}>
            CHIRPY REVERIE IS APPROACHING...
            </div>
        </Row>
    </Container>
    </>
    );
}

const ExtendedMintComingDefault = withPageConfig(MintComingDefault);

export {
    ExtendedMintComingDefault as MintComingDefault
};