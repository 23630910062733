import { Layout } from './Layout';
import { LayoutContent } from './LayoutContent';
import { LayoutShowBoxYellow } from './LayoutShowBoxYellow';
import { LayoutShowBoxBlue } from './LayoutShowBoxBlue';
import { LayoutBlackBox } from './LayoutBlackBox';
import { LayoutFooter } from './LayoutFooter';
import { LayoutNavbar } from './LayoutNavbar';
import { LayoutSidebar } from './LayoutSidebar';
import { withPageConfig } from './withPageConfig';
import { setupPage } from './setupPage';
import { PageConfigContext } from './PageConfigContext';

Layout.Sidebar = LayoutSidebar;
Layout.Navbar = LayoutNavbar;
Layout.Content = LayoutContent;
Layout.ShowBoxYellow = LayoutShowBoxYellow;
Layout.ShowBoxBlue = LayoutShowBoxBlue;
Layout.BlackBox = LayoutBlackBox;
Layout.Footer = LayoutFooter;

const PageConfigProvider = PageConfigContext.Provider;
const PageConfigConsumer = PageConfigContext.Consumer;

export default Layout;
export { withPageConfig, setupPage, PageConfigProvider, PageConfigConsumer };
