// ShowAvatarDataDef

// colors
const AvatarColors = [
    "ee4645","ff8a3c","ffce3f","6bc44f","29cfd0","119fec","965bbb", // base
    "d52120","e26919","e3b01c","4cb22b","12b5b6","0985c9","7d3ca5", // dark1
    "b70100","b94a00","c59300","30980f","009394","0069a3","681f94", // dark2
    "ff8483","ffa86e","ffda6e","90db78","5cdfe0","44bdff","ba87da", // light1
    "ffbebd","ffd0b0","ffebaf","bdecae","a1edee","87d5ff","dfbdf4", // light2
    "ad3812","0f2daa","c0278b","6d1f07","a7061e","169d4b","9433b7","2191e7","ce5b19","fdb92c","e20a17","5056cc","556384","ee6285","a9507d","de8bb0","b1325b","678f6a","418371","e59d57","757fa7","ee6171","1cb3b7","7569ab","b2cb53","58b4a5","779e5b","30a5c4","ed7a49","e2ad4e","579427","c50824","9c0e74","388dc1","185b93","2c8b8e","d62754","b62d72","9f5c15","4975b4", // dark other
    "cadfe9","feca74","fdf38f","fddcd2","ffcece","ffd4a3","efe68e","a8c4f0","eec8ec","daf6d1","c8f6f7","c5eafe","eedcfa","dee9ff", // light other
    "dcdcdc","aaaaaa","787878","464646","282828","efefef","fafafa",   // black and white
];

// grade num
const GradeID = {
    MG_WHITE:0,
    MG_GREEN:1,
    MG_BLUE:2,
    MG_PURPLE:3,
    MG_RED:4,
}

// medal id
const MedalTypeID = {
    MT_NORMAL:0,
    MT_SILVER:1,
    MT_GOLD:2,
}

// 获取基础颜色
function GetBaseColor(skillType) {
    if(skillType >= AvatarColors.length) {
        return "ffffff";
    }
    return AvatarColors[skillType];
}

// 获取品阶
function GetGradeID(gradeName) {
    return GradeID[gradeName];
}

// 获取精英类型
function GetMedalTypeID(medalName) {
    return MedalTypeID[medalName];
}

// 获得标识列表
function GetFlags(grade, eliteType) {
    let _flags = [false,false,false,false,false,false,false];
    _flags[grade] = true;
    if (eliteType === 1) _flags[5] = true;
    if (eliteType === 2) _flags[6] = true;
    return _flags;
}

// 解析部分数据
function GetShowAvatarData_Part(pdata) {
    return {
        id: pdata.id<10 ? '0'+pdata.id : ''+pdata.id,
        color: AvatarColors[pdata.color],
    };
}

// 解析全部数据
function GetShowAvatarData(mdata) {
    return {
        body: GetShowAvatarData_Part(mdata.body),
        handL: GetShowAvatarData_Part(mdata.handL),
        handR: GetShowAvatarData_Part(mdata.handR),
        footL: GetShowAvatarData_Part(mdata.footL),
        footR: GetShowAvatarData_Part(mdata.footR),
        legL: GetShowAvatarData_Part(mdata.legL),
        legR: GetShowAvatarData_Part(mdata.legR),
        nose: GetShowAvatarData_Part(mdata.nose),
        mouth: GetShowAvatarData_Part(mdata.mouth),
        eyes: GetShowAvatarData_Part(mdata.eyes),
        ears: GetShowAvatarData_Part(mdata.ears),
        beard: GetShowAvatarData_Part(mdata.beard),
        hair: GetShowAvatarData_Part(mdata.hair),
        frontHornL: GetShowAvatarData_Part(mdata.frontHornL),
        frontHornR: GetShowAvatarData_Part(mdata.frontHornR),
        backHornL: GetShowAvatarData_Part(mdata.backHornL),
        backHornR: GetShowAvatarData_Part(mdata.backHornR),
        wing: GetShowAvatarData_Part(mdata.wing),
        forehead: GetShowAvatarData_Part(mdata.forehead),
        glasses: GetShowAvatarData_Part(mdata.glasses),
        pet: GetShowAvatarData_Part(mdata.pet),
    };
}

export {
    AvatarColors,
    GradeID,
    MedalTypeID,

    GetShowAvatarData_Part,
    GetShowAvatarData,
    GetFlags,
    GetGradeID,
    GetMedalTypeID,
    GetBaseColor,
};