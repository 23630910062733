// Combat Module

import { CreateRandomMonsterEgg } from "./MonsterData";

// skill type
const SKILL_fIRE = 0;
const SKILL_POWER = 1;
const SKILL_LIGHT = 2;
const SKILL_NATURAL = 3;
const SKILL_MAGICDEF = 4;
const SKILL_ICE = 5;
const SKILL_POWERDEF = 6;
const SKILL_MAXID = 7;

// 返回颜色相似差值
// 值域范围：【0, 255】，值越大，越不相似
// 传入参数示意，如：color1 = "ffffff"
function getColorSimilarity(color1, color2) {
    const colorLen = color1.length;
    let unitDifferences = Array(colorLen).fill(0);
    for (let i=0; i<colorLen; i++) {
        unitDifferences[i] = Math.abs(parseInt(color1[i], 16) - parseInt(color2[i], 16));
    }
    let RGBDiff = [
        unitDifferences[0] + unitDifferences[1],
        unitDifferences[2] + unitDifferences[3],
        unitDifferences[4] + unitDifferences[5],
    ];
    return RGBDiff.reduce((pre, cur) => pre + cur) / RGBDiff.length;
}

// 计算战斗能力值（根据各部件的色彩偏差）
// 品阶决定了能力基数，累积色差决定了能力偏差
function getCombatAbility(avatarData, grade, baseColor, level=10, maxLevel=10) {
    // 能力基值
    const basePointNum = 1000;
    let allPointNum = (basePointNum + grade*basePointNum/4) * (level/maxLevel);
    // 统计色差
    let sumColorDiffNum = 0;
    Object.entries(avatarData).forEach(([k, v]) => { 
        if (v["id"] != "-1") {
            sumColorDiffNum += getColorSimilarity(baseColor, v["color"]);
        }
    })
    // 平均色差
    let averageColorDiff = sumColorDiffNum / Object.keys(avatarData).length;
    // 实际能力值
    let pointRate = (20 - averageColorDiff) / 20;
    if (pointRate < 0) pointRate = 0;
    if (pointRate > 1) pointRate = 1;
    let currPointNum = (allPointNum / 2) + allPointNum * pointRate;

    return Math.round(currPointNum);
}

// 单对象战斗属性计算
// 传入参数：cdata = { skillType:XX, combatAbility:XX, score:XX }
function getCombatAttrForOneVsOne(cdata) {
    cdata["HP"] = 100;

    cdata["BaseAttack"] = cdata["combatAbility"] / 3000;
    cdata["BaseDef"] = cdata["score"] / 10000;

    return cdata;
}
// 单对象PK结算过程
// 传入参数：mine = { skillType:XX, combatAbility:XX, score:XX }
function toChallengeOneMonster(mine, target) {
    mine = getCombatAttrForOneVsOne(mine);
    target = getCombatAttrForOneVsOne(target);
    // 胜负结果
    let _result = false;
    // 临时变量
    let _mineAtt = mine["BaseAttack"];
    let _mineDef = mine["BaseDef"];
    let _mineHP = mine["HP"];
    let _targetAtt = target["BaseAttack"];
    let _targetDef = target["BaseDef"];
    let _targetHP = target["HP"];
    let _mineDoubleRate = ((mine["skillType"]+1)%7 === target["skillType"]) ? 0.1 : 0;
    let _targetDoubleRate = ((target["skillType"]+1)%7 === mine["skillType"]) ? 0.1 : 0;
    let _isMine = (Math.random() < 0.5) ? true : false;
    let currHurt = 0;
    //console.log(mine);
    //console.log(target);
    //console.log("--------> 战斗开始 <--------");
    // 有限次数没有胜出则失败
    for ( let i=0; i<100; i++ ) {
        if (_isMine) {
            currHurt = (_mineAtt / (_mineAtt + _targetDef)) * 20;
            currHurt = (Math.random() < _mineDoubleRate) ? currHurt*2 : currHurt;
           _targetHP -= currHurt;
           //console.log("对方HP：", _targetHP, currHurt, i);
        }
        else {
            currHurt = (_targetAtt / (_targetAtt + _mineDef)) * 20;
            currHurt = (Math.random() < _targetDoubleRate) ? currHurt*2 : currHurt;
            _mineHP -= currHurt
            //console.log("我方HP：", _mineHP, currHurt, i);
        }
        // 结果判定
        if (_mineHP <= 0) break;
        if (_targetHP <= 0 && _mineHP > 0) {
            _result = true;
            break;
        }
        // 交换攻击者
        _isMine = !_isMine;
    }
    //console.log("是否挑战成功：", _result);
    // 返回
    return _result;
}

// 随机对战
function toRandChallengeOneMonster() {
    let _mine = CreateRandomMonsterEgg(2);
    let _target = CreateRandomMonsterEgg(2);
    //_mine["skillType"] = 0;
    //_target["skillType"] = 1;

    for (let i=0; i<10; i++) {
        _mine.levelUp();
        _target.levelUp();
    }

    return toChallengeOneMonster(
        { skillType: _mine.skillType, combatAbility: _mine.combatAbility, score: _mine.score },
        { skillType: _target.skillType, combatAbility: _target.combatAbility, score: _target.score },
    );
}
// 随机对战指定次数
function toRandChallengeForNum(num=1000) {
    let winTime = 0;
    for (let i=0; i<num; i++) {
        if (toRandChallengeOneMonster()) winTime += 1;
    }
    console.log( winTime, num );
}

export {
    getCombatAbility,
    toChallengeOneMonster,
    toRandChallengeOneMonster,
    toRandChallengeForNum,
};
