// TeamDefault
import React from 'react';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Badge,
} from '../../../components';

class TeamDefault extends React.Component {

    render() {
        return (
            <Container>
                <Row className="align-items-center justify-content-xl-between">
                    <div style={{width: "100%", fontSize: "40px", fontWeight: "800", textAlign: "center"}}>
                    Who We Are
                    </div>
                    <div style={{padding:"15px", fontWeight: "700"}}>
                    HORAC STUDIO is a small, independent and diverse games studio. We met in the open source community and it started with an interesting discussion. We are committed to leveraging emerging technologies to change the way people live their entertainment lives.
                    </div>
                </Row>
                <Container style={{padding:"10px"}}>
                    <Row className="align-items-center">
                        <Badge color="dark" pill  style={{width: "100%"}}>
                            <div style={{fontSize:"25px", color:"#fff", fontWeight:"700", margin:"8px 8px"}}>
                            <i className="fab fa-teamspeak"> Team</i>
                            </div>
                        </Badge>
                        <div style={{padding:"10px"}}>
                        Our team has a solid background in game development, but we don't see ourselves as just a game studio. We love what we do. We see ourselves as creators of interactive art. Our goal is to create a completely different and fully connected entertainment experience for you.
                        </div>
                    </Row>
                </Container>
                <Row>
                    <Col xl="1"></Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg4.png"/><br/>
                        <b>Kim Jennie</b><br/>
                        CEO-Founder
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg2.png"/><br/>
                        <b>Shaojun Chen</b><br/>
                        CTO
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg5.png"/><br/>
                        <b>Jacko</b><br/>
                        Lead Developer
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg3.png"/><br/>
                        <b>Kingly Meta</b><br/>
                        Lead Designer
                    </Col>
                    <Col xl="2" className="imgColCenter">
                        <img src="/img/headimg1.png"/><br/>
                        <b>Cofirei</b><br/>
                        Marketing and Sales
                    </Col>
                    <Col xl="1"></Col>
                </Row>
            </Container>
        );
    }
}

const ExtendedTeamDefault = withPageConfig(TeamDefault);

export {
    ExtendedTeamDefault as TeamDefault
};