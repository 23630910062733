import axios from 'axios';

const GAMEAPI_URL = process.env.REACT_APP_GAME_API;
const MINT_BASE_URL = process.env.REACT_APP_MINT_BASEURL;

function getRefUrl(refcode) {
    return MINT_BASE_URL+refcode;
}

const gameapi = axios.create({
    baseURL: GAMEAPI_URL,
    timeout: 2000,
});

let user_headers = {};

export {
    gameapi,
    user_headers,
    getRefUrl
};
