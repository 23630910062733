import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
// import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { Web3Provider } from '@ethersproject/providers';

import { UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector';

import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector';

const POLLING_INTERVAL = 6000;

const RPC_URLS = {
    137: 'https://polygon-rpc.com',
    80001: 'https://rpc-mumbai.maticvigil.com/',
    31337: 'http://localhost:8545'
};

export const injected = new InjectedConnector({ supportedChainIds: [137, 80001, 31337] });

export const walletconnect = new WalletConnectConnector({
    rpc: RPC_URLS,
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
    supportedChainIds: [137, 80001]
});

export const connectorsByName = {
    Injected: injected,
    WalletConnect: walletconnect,
}

export function getLibrary(provider) {
    if (!provider) return null;

    const _library = new Web3Provider(provider);
    _library.pollingInterval = POLLING_INTERVAL;
    return _library;
}
