// WhitepaperDefault
import React from 'react';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col,
} from '../../../components';

class WhitepaperDefault extends React.Component {

    render() {
        return (
            <Container>
                <Row className="align-items-center justify-content-xl-between">
                    <div style={{width: "100%", fontSize: "40px", fontWeight: "800", textAlign: "center"}}>
                    Whitepaper
                    </div>
                </Row>
                <Row className="align-items-center justify-content-xl-between">
                    <Col xl="4">
                        <div className="home-slogan-min" style={{width: "379px"}}>
                            COLLECT.BATTLE.TEAM.STAKE.<br/>
                            CULTIVATE.ADVENTURE.EARN.
                        </div>
                    </Col>
                    <Col xl="5">
                        <div style={{height: "10px", width: "475px"}}></div>
                    </Col>
                    <Col xl="3">
                        <div className="home-slogan-min" style={{width: "285px"}}>
                            RANKING.TREASURE.<br/>
                            REPRODUCTION.LUCK.
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="2" className="imgCol">
                        <img src="/img/game4.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/>
                        <img src="/img/game3.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/><br/>
                        <img src="/img/game0.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/><br/>
                        <img src="/img/game2.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <br/>
                        <img src="/img/game1.png"/>
                    </Col>
                    <Col xs="2" className="imgCol">
                        <img src="/img/game5.png"/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const ExtendedWhitepaperDefault = withPageConfig(WhitepaperDefault);

export {
    ExtendedWhitepaperDefault as WhitepaperDefault
};