/*global PIXI, dragonBones*/
/*eslint no-undef: "error"*/
/*eslint no-undef: ["error", { "typeof": true }] */

import React from 'react';

import { useScript, useSeqScripts } from '../../../hooks';
import { withModel } from "hox";

import usePresaleAdoptMonster from '../../../models/PresaleModel';

const BACKGROUND_URL = "/img/mbg.jpg";
const BACKGROUND_WIDTH = 896;
const BACKGROUND_HEIGHT = 512;

const MONSTER_WIDTH = 270;
const MONSTER_SCALE = 0.768;

// 窗口大小
// const WindowWidth = 700;
// const WindowHeight = 400;

// 标识资源
const FlagsName = [
    "/resource/flags/flag0.png",
    "/resource/flags/flag1.png",
    "/resource/flags/flag2.png",
    "/resource/flags/flag3.png",
    "/resource/flags/flag4.png",
    "/resource/flags/medalSilver.png",
    "/resource/flags/medalGold.png"
];

const LoadingView = ({ status }) => (
    <div>
        Script status: <b>{status}</b>
    </div>
);

let _pixiResources = {};

class DragonbonesWidget extends React.Component {
    constructor(props) {
      super(props);

      this._loadResources = this._loadResources.bind(this);
      this._onResize = this._onResize.bind(this);
      this._applyResize = this._applyResize.bind(this);

      this.viewRef = React.createRef();
      this.app = null;

      this._resources = props.resources || [];
      this._resources.push(BACKGROUND_URL);
      this._resources.push(
        "/resource/avatar/MA_ske.dbbin"
        )
      for (let i=0; i<15; i++) {
        this._resources.push(
            `/resource/avatar/MA_tex_${i}.json`,
            `/resource/avatar/MA_tex_${i}.png`
        )
      }
      for (let i=0; i<FlagsName.length; i++) {
        this._resources.push( FlagsName[i] );
      }
      
      this._background = null;
      this._loader = null;
      this._scene = null;

      this._medalSprs = [];

      this.state = {
          sceneReady: false,
      };
    }

    componentDidMount() {
        this.app = new PIXI.Application({
            // width: WindowWidth, height: WindowHeight, 
            backgroundColor: 0x000000, //transparent: true, 
            resizeTo: this.viewRef.current,
            autoDensity: true,
            resolution: window.devicePixelRatio || 1,
        });

        this.viewRef.current.appendChild(this.app.view);
        
        this._loader = new PIXI.Loader();

        //console.log("---LOADERS--->", this.state.flags, this._loader, this._resources);
        this._loadResources();

        window.addEventListener('resize', this._onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize);
        this.viewRef.current.removeChild(this.app.view);
        this._background = null;
        this._loader = null;
        this._scene = null;
        this.app = null;
    }

    _onResize(event) {
        const new_width = this.viewRef.current.offsetWidth;
        const new_height = this.viewRef.current.offsetHeight;
        this._applyResize(new_width, new_height);
    }

    _applyResize(new_width, new_height) {
        const _cx = new_width/2;
        const _cy = new_height/2;

        const _bgScaleW = new_width/BACKGROUND_WIDTH;

        const _mbg = this._mbg;
        if (_mbg) {
            _mbg.scale.x = _bgScaleW;
            _mbg.scale.y = new_height/BACKGROUND_HEIGHT;
            _mbg.position.x = _cx;
            _mbg.position.y = _cy;
        }

        const scene = this._scene;
        if (scene) {
            scene.x = _cx;
            scene.y = _cy + 10;
            scene.setScale(_bgScaleW * MONSTER_SCALE);
        }

        for (let i=0; i<FlagsName.length; i++) {
            const newSpr = this._medalSprs[i];
            if(newSpr) {
                if(i<5) {
                    newSpr.position.x = 60;
                }
                else {
                    newSpr.position.x = new_width - 60;
                }    
            }
        }
    }

    _loadResources() {
        const binaryOptions = { loadType: PIXI.LoaderResource.LOAD_TYPE.XHR, xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BUFFER };

        for (const resource of this._resources) {
            if (resource in _pixiResources) continue;

            if (resource.indexOf("dbbin") > 0) {
                this._loader.add(resource, resource, binaryOptions);
            }
            else if (resource.indexOf(".svg") > 0) {
                this._loader.add(resource, resource); //, {metadata:{resourceOptions: {width:1024, height:576}}});
                //  {metadata:{resourceOptions: {scale:1.5}}}
            }
            else {
                this._loader.add(resource, resource);
            }
        }

        this._loader.load((loader, resources) => {
            _pixiResources = { ..._pixiResources, ...resources };

            var DragonBonesScene = require('./DragonBonesScene').default;

            let jsonDataList = []
            for (let i=0; i<15; i++) jsonDataList.push( _pixiResources[`/resource/avatar/MA_tex_${i}.json`].data );
            let texDataList = []
            for (let i=0; i<15; i++) texDataList.push( _pixiResources[`/resource/avatar/MA_tex_${i}.png`] );
            const scene = new DragonBonesScene(
                _pixiResources["/resource/avatar/MA_ske.dbbin"].data,
                jsonDataList, texDataList
            );

            const _mbg = new PIXI.Sprite(_pixiResources[BACKGROUND_URL].texture);
            _mbg.anchor.set(0.5);

            this.app.stage.addChild(_mbg);
            this._mbg = _mbg;

            this.app.stage.addChild(scene);
            this._scene = scene;

            this._applyResize(this.app.screen.width, this.app.screen.height);

            // flags
            for (let i=0; i<FlagsName.length; i++) {
                let newSpr = new PIXI.Sprite(_pixiResources[FlagsName[i]].texture);
                newSpr.anchor.set(0.5);
                newSpr.position.y = 60;
                if(i<5) {
                    newSpr.position.x = 60;
                }
                else {
                    newSpr.position.x = this.app.screen.width - 60;
                }
                newSpr.visible = false;
                this._medalSprs.push(newSpr);
                this.app.stage.addChild(newSpr);
            }

            scene._onStart();
            this.setState({sceneReady: true});
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.state.sceneReady) return;

        const { avatarData, medalFlags } = this.props;
        if(this._scene) this._scene.setAvatarData(avatarData);
        // flags
        for (let i=0; i<medalFlags.length; i++) {
            this._medalSprs[i].visible = medalFlags[i];
        }
    }

    render() {
        return <div className="mscene"
                style={{
                    position: "relative",
                    paddingBottom: "57.143%" /* 896:512 */,
                    paddingTop: 25,
                    height: 0
                }}
                ref={this.viewRef}
            />;
    }
}

// const DragonbonesWidget = withModel(usePresaleAdoptMonster, monsterData => ({
//     monsterData
// }))(_DragonbonesWidget);

const _pixi_scripts = [
    "https://cdnjs.cloudflare.com/ajax/libs/pixi.js/6.1.3/browser/pixi.min.js",
    "/static/js/dragonBones.min.js"
];

function MonsterViewPixi(props) {
    const scripts_status = useSeqScripts(_pixi_scripts);

    const ready = scripts_status === "ready";

    return (
        <div className="mb-3 ani-panel">
            {
                ready ? <DragonbonesWidget {...props}/> : <LoadingView status={scripts_status}/>
            }
        </div>
    );
}

export default MonsterViewPixi;