import { ethers } from 'ethers';
import MonstersPreSale from '../artifacts/MonstersPreSale.json';
import MonstersNFTContract from '../artifacts/MonsterNFT.json';
import RefPrizeContract from '../artifacts/RefPrizePresale.json';

const ContractsAddresses = {
    80001: {
        MonsterNFT: '0xe4Ab3d41555bBA85bFA3dF1C9A43862335662835',
        MonstersPreSale: '0x40104798627AC700fa094eF27637219f6685F442',
        RefPrizePresale: '0x89f4D2670be5AE5CeaDF1baB231fd7d8BBe31a72',
    },
    137: {
        MonsterNFT: '0xC79cC223B99c51363c29c5b520a3D13707504947',
        MonstersPreSale: '0x7c72c7aCaa71e775ebcbb746f7876E8029e2FB3e',
        RefPrizePresale: '0x8382e7Eb03914e80F97dC90A1bE6796A9f78773c',
    }
};

var presaleContract = null;
var monsterNFT = null;
var refPrize = null;

function setupContracts(chainId, library) {
    const preSaleAddress = ContractsAddresses[chainId].MonstersPreSale;
    const monsterNFTAddress = ContractsAddresses[chainId].MonsterNFT;
    const refPrizeAddress = ContractsAddresses[chainId].RefPrizePresale;

    presaleContract = new ethers.Contract(preSaleAddress, MonstersPreSale.abi, library);
    monsterNFT = new ethers.Contract(monsterNFTAddress, MonstersNFTContract.abi, library);
    refPrize = new ethers.Contract(refPrizeAddress, RefPrizeContract.abi, library);
}

export {
    setupContracts,
    presaleContract,
    monsterNFT,
    refPrize
};
