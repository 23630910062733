// PromotionsDefault
import React, { useEffect, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Badge, Alert, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from '../../../components';

import { Spinner } from '../components/Spinner';
 
import useWalletData from '../../../models/WalletModel';

// 未登录
function NoLoginPanel() {
    const {
        isConnected, 
        toggleModalConnectWallet,
        getRedemptionVouchers
    } = useWalletData();

    useEffect(() => {
        getRedemptionVouchers();
    }, [isConnected]);

    return (
        <div>
            <Container className="infoBox">
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont" style={{color:"#000"}}>
                        <i className="fas fa-exclamation-circle"> You Are Not Logged In</i>
                    </h4>
                </Row>
                <Row>
                <div>
                    <li><b>
                        Before you can open the treasure chest, you need to log in.
                    </b></li>
                    <li><b>
                        Each link can only be used once, and if someone had used it, it will be invalid.
                    </b></li>
                </div>
                </Row>
                <Row style={{height:"20px"}}></Row>
                <Row>
                    <Button block color="danger" onClick={() => {
                        toggleModalConnectWallet();
                    }}>
                        <i className="fas fa-user"> <b> Sign in now</b></i>
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

// 登录后推广信息显示
function InfoShowPanel({coupon}) {
    const {
        redemptionVouchers, getRedemptionVouchers,
        claimReward, claimRewardSteps,
        isClaimStepsPanelOpen, toggleClaimStepsPanel
    } = useWalletData();

    const [isNumShow, setIsNumShow] = useState(false);

    const isWaitting = claimRewardSteps.error === null && claimRewardSteps.step != 3;

    return (
        <div>
            <Modal centered isOpen={isClaimStepsPanelOpen} className="modal-outline-warning">
                <ModalHeader tag="h6">
                    <span className="text-warning">
                        { claimRewardSteps.title || "Claiming reward" }
                    </span>
                </ModalHeader>
                <ModalBody>
                    <div style={{textAlign: "center"}}>
                    <i className="fas fa-paw"> STEP { claimRewardSteps.step } </i> <br/>
                    <i className="fas fa-map-marker-alt" style={ claimRewardSteps.step>=1 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                    &nbsp;&nbsp;……&nbsp;&nbsp;
                    <i className="fas fa-map-marker-alt" style={ claimRewardSteps.step>=2 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                    &nbsp;&nbsp;……&nbsp;&nbsp;
                    <i className="fas fa-map-marker-alt" style={ claimRewardSteps.step>=3 ? {color: "#ff0000"}:{color: "#aaaaaa"}}/>
                    <br/>
                    {
                        isWaitting ? <Spinner color="red" width="30px"/> : <br/>
                    }
                    </div>
                    <i className="fas fa-info-circle"> { claimRewardSteps.message || claimRewardSteps.error } </i>
                </ModalBody>
                {
                    isWaitting ? null : <ModalFooter><Button color="warning" onClick={()=>{toggleClaimStepsPanel();}}>Close</Button></ModalFooter>
                }
            </Modal>

            <Container className="accordion">
                <Row className="align-items-center justify-content-xl-between">
                    <div style={{width: "100%", textAlign: "center"}}>
                        <img src="/img/TreasureChest/Open5.png"/>
                    </div>
                    {isNumShow ? (<h4 className="home-titlecenterfont" style={{color:"#fff"}}>
                        <b>Your Monster Redemption Vouchers: </b>
                        &nbsp;
                        <Badge color="light" pill>
                            <div style={{fontSize:"25px", fontWeight:"800", margin:"8px 8px"}}>
                            &nbsp;<i className="fas fa-star-of-david" style={{color:"#ff0000"}}>
                            &nbsp;{Math.round(redemptionVouchers)}</i> &nbsp;
                            </div>
                        </Badge>
                        &nbsp;&nbsp;
                        <Button color="warning" onClick={getRedemptionVouchers}>
                            <i className="fas fa-sync-alt"></i>
                        </Button>
                        <div style={{paddingTop:"10px", fontSize:"18px", color:"#ff9"}}>
                        <i className="fas fa-info-circle"> Please wait and refresh after successful claim.</i>
                        </div>
                        <div style={{paddingTop:"10px", fontSize:"18px", color:"#fff"}}>
                        Go to the
                        <Link to="/promotions" className="text-primary-light"> &nbsp;promotions page&nbsp; </Link>
                        and adopt a monster.
                        </div>
                    </h4>) : null}
                </Row>
                <Row className="align-items-center justify-content-xl-between" style={{marginTop:"15px"}}>
                    <Button block color="danger" onClick={() => {
                        setIsNumShow(true);
                        toggleClaimStepsPanel();
                        claimReward(coupon);
                    }}>
                        <i className="fas fa-hand-point-right">&nbsp; <b> Claim Reward</b></i>
                    </Button>
                </Row>
            </Container>
            <div style={{paddingTop:"10px", width: "100%", textAlign: "center", color:"#003300", fontWeight:"700"}}>
                Don't want to miss out next time? Then follow us on
                <a 
                href="https://twitter.com/TMorganer"
                rel="The Monster Kings"
                target="_blank" 
                className="text-primary-light">
                    &nbsp; Twitter &nbsp;
                </a>
                and
                <a 
                href="https://t.me/TheMonsterKingsChannel"
                rel="The Monster Kings Channel"
                target="_blank"
                className="text-primary-light">
                    &nbsp; Telegram &nbsp;
                </a>
                .
            </div>
            <div style={{width: "100%", textAlign: "center", color:"#003300", fontWeight:"700"}}>
                <i className="fas fa-question-circle" />&nbsp;&nbsp;If you have questions, please
                <a 
                href="https://t.me/+IkRkGiZmJk81NDUx"
                rel="The Monster Kings Community"
                target="_blank"
                className="text-primary-light">
                    &nbsp; Join our Community &nbsp;
                </a>
                .
            </div>
        </div>
    );
}

function PromoRewardsDefault(props) {
    const { isConnected } = useWalletData();

    const { coupon } = props.match.params;
    
    return (
        <Container>
            <Row className="align-items-center justify-content-xl-between">
                <h4 className="home-titlecenterfont">
                Get Monster Redemption Vouchers
                </h4>
            </Row>

            { isConnected ? null:<NoLoginPanel /> }
            { isConnected ? <InfoShowPanel coupon={coupon}/>:null }
            
        </Container>
    );
}

const ExtendedPromoRewardsDefault = withPageConfig(PromoRewardsDefault);

export {
    ExtendedPromoRewardsDefault as PromoRewardsDefault
};
