import React from "react";

// reactstrap components
// import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { NavLink as Link } from 'react-router-dom';
import {
    Container, Row, Col, Nav, NavItem, NavLink, Button
} from '../../../../components';

// tag={ Link } target="_blank" to={{pathname: "https://t.me/TheMonsterKingsChannel"}}
function HomeJoinOur() {
    return (
        <Container>
        <Row className="align-items-center justify-content-xl-between">
            <Col xl="4" className="home-slogan-min">
                <div style={{width: "400px"}}>
                <Row className="align-items-center justify-content-xl-between">
                <Col  xl="5">
                Join our Community
                </Col>
                <Col  xl="7">
                <Button color="light" outline style={{width: "145px"}} onClick={ () => {window.open("https://t.me/+IkRkGiZmJk81NDUx", "_blank")} } >
                    <i className="fas fa-hand-point-right"> Join Now!</i>
                </Button>
                </Col>
                </Row>
                </div>
            </Col>

            <Col xl="5">
                <div style={{height: "100px", width: "450px"}}>
                <img className="img-logo img-fluid" src="/img/logo.png" style={{position: "relative", top:"0px", zIndex:"110"}}/>
                </div>
            </Col>

            <Col xl="3" style={{fontSize: "45px"}}>
                <Nav className="nav-footer justify-content-center">
                <NavItem>
                    <NavLink
                    href="https://t.me/TheMonsterKingsChannel"
                    rel="The Monster Kings Channel"
                    target="_blank"
                    >
                    <i className="fab fa-telegram fa-2x"></i>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                    href="https://horacstudio.medium.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >
                    <i className="fab fa-medium fa-2x"></i>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                    href="https://twitter.com/TMorganer"
                    rel="noopener noreferrer"
                    target="_blank"
                    >
                    <i className="fab fa-twitter fa-2x"></i>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                    href="https://www.youtube.com/channel/UCyrWJGz9gWVpfPsijXCpMlw"
                    rel="noopener noreferrer"
                    target="_blank"
                    >
                    <i className="fab fa-youtube fa-2x"></i>
                    </NavLink>
                </NavItem>
                </Nav>
            </Col>
        </Row>
      </Container>
    );
}

export default HomeJoinOur;