import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import classNames from 'classnames';

import { 
    Nav,
    DropdownToggle,
    NavLink,
    UncontrolledDropdown,
    NavItem,
    DropdownMenu,
    DropdownItem,
    NestedDropdown
} from './../../../components';

const NavbarNavigation = ({ accent, pills, ...navbarProps }) => (
    <Nav navbar accent={ accent } pills={ pills } { ...navbarProps }>
        <NavItem>
            <NavLink tag={ Link } to="/home">
                <span className={ classNames({ 'mr-3': !(pills || accent) }) }> 
                    <span>
                        Home
                    </span>
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={ Link } to="/mint">
                <span className={ classNames({ 'mr-3': !(pills || accent) }) }> 
                    <span> Mint </span>
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={ Link } to={{pathname: "/whitepaper/index.html"}} target="_blank">
                <span className={ classNames({ 'mr-3': !(pills || accent) }) }> 
                    <span> Whitepaper </span>
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={ Link } to="/promotions">
                <span className={ classNames({ 'mr-3': !(pills || accent) }) }> 
                    <span> Promotions </span>
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={ Link } to="/dashboard">
                <span className={ classNames({ 'mr-3': !(pills || accent) }) }> 
                    <span> Dashboard </span>
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={ Link } to="/faq">
                <span className={ classNames({ 'mr-3': !(pills || accent) }) }> 
                    <span> FAQ </span>
                </span>
            </NavLink>
        </NavItem>
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
                <i className="fas fa-globe"></i>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem>English</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    </Nav>
);
// <DropdownItem divider />
// <DropdownItem tag={ Link } to="/language/繁体中文">繁体中文</DropdownItem>
// <DropdownItem tag={ Link } to="/language/简体中文">简体中文</DropdownItem>

NavbarNavigation.propTypes = {
    pills: PropTypes.bool,
    accent: PropTypes.bool,
};


export { NavbarNavigation };
