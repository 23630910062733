import React from "react";

import {
    Container, Row, Col, Nav, NavItem, NavLink, Button
} from '../../../../components';

function HomeWhyBuy() {
    return (
        <div>
            <Container>
                <Row>
                    <Col xl="4">
                        <Container className="infoLittleBox" style={{background:"#955eb9"}}>
                            <Row className="align-items-center justify-content-xl-between">
                                <h5 className="home-titlecenterfont" style={{color:"#000"}}>
                                    <i className="fas fa-paw"/><br/> A Rarity of Your <br/>Own Creation
                                </h5>
                            </Row>
                            <Row>
                            <div style={{color:"rgba(255,255,255,0.8)"}}>
                                <li><b>
                                Find unique monsters in a huge range of combinations.
                                </b></li>
                                <li><b>
                                Evolution of monsters through reproduction.
                                </b></li>
                                <li><b>
                                Collecting the characteristics of monsters for artificially directed evolution.
                                </b></li>
                                <li><b>
                                Sell, swap your monsters, or raise them to become king of the monsters.
                                </b></li>
                                <li><b>
                                And more...
                                </b></li>
                            </div>
                            </Row>
                        </Container>
                    </Col>
                    <Col xl="4">
                        <Container className="infoLittleBox" style={{background:"#22a1e9"}}>
                            <Row className="align-items-center justify-content-xl-between">
                                <h5 className="home-titlecenterfont" style={{color:"#000"}}>
                                    <i className="fas fa-paw"/><br/> Rich Gameplay
                                </h5>
                            </Row>
                            <Row>
                            <div style={{color:"rgba(255,255,255,0.8)"}}>
                                <li><b>
                                Treasure Hunt: Go to the Treasure Map to search for minerals.
                                </b></li>
                                <li><b>
                                Combat: Single or team challenges against other monsters.
                                </b></li>
                                <li><b>
                                Adventure: Defeat the different monster kings in the forest.
                                </b></li>
                                <li><b>
                                Money: Win big on leaderboards, quests or raffles.
                                </b></li>
                                <li><b>
                                And more...
                                </b></li>
                            </div>
                            </Row>
                        </Container>
                    </Col>
                    <Col xl="4">
                        <Container className="infoLittleBox" style={{background:"#fb9f39"}}>
                            <Row className="align-items-center justify-content-xl-between">
                                <h5 className="home-titlecenterfont" style={{color:"#000"}}>
                                    <i className="fas fa-paw"/><br/> Governance
                                </h5>
                            </Row>
                            <Row>
                            <div style={{color:"rgba(255,255,255,0.8)"}}>
                                <li><b>
                                Your monster is able to earn the community token to be used for voting.
                                </b></li>
                                <li><b>
                                The community decides the future direction of the game.
                                </b></li>
                                <li><b>
                                Community shareholders receive an ongoing high revenue share of the game.
                                </b></li>
                                <li><b>
                                And more...
                                </b></li>
                            </div>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HomeWhyBuy;