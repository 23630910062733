import React from 'react';
import PropTypes from 'prop-types';

const LayoutFooter = (props) => (
    <div className="layout__footer">
        { props.children }
    </div>
);

LayoutFooter.propTypes = {
    children: PropTypes.node
};
LayoutFooter.layoutPartName = "footer";

export {
    LayoutFooter
};

