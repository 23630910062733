import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// import { LogoThemed } from './../LogoThemed/LogoThemed';

const HeaderAuth = (props) => (
    <div className="mb-4">
        <div className="mb-4 text-center">
            <img src="/img/logo.png" style={{width:"300px"}}/>
        </div>
        <h1 className="text-center mb-4">
            { props.title }
        </h1>
        <p className="text-center">
            { props.text }
        </p>
    </div>
)
HeaderAuth.propTypes = {
    icon: PropTypes.node,
    iconClassName: PropTypes.node,
    title: PropTypes.node,
    text: PropTypes.node,
};
HeaderAuth.defaultProps = {
    title: "Waiting for Data...",
    text: "It looks like nothing was found at this location. Maybe try a search?",
    iconClassName: "text-theme"
};

export { HeaderAuth };
