import { ethers } from 'ethers';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { UnsupportedChainIdError } from '@web3-react/core';

import { connectorsByName, getLibrary } from './connectors';
import { setupContracts, presaleContract, monsterNFT, refPrize } from './contracts';

import { gameapi, user_headers, getRefUrl } from './apiclient';

import { errorCodes } from 'eth-rpc-errors';

const rpcCodes = errorCodes.rpc;

let _library = null;
let _currSigner = null;

let _presaleWithSigner = null;
let _monsterNFTWithSigner = null;
let _refPrizeWithSigner = null;

const MAX_NFT_NUM = ethers.BigNumber.from(2).pow(192);
const ITEM_GOLD = MAX_NFT_NUM.add(1);
const ITEM_COOKIE = MAX_NFT_NUM.add(2);

const EVENT_ITEM_BEGIN_NUM = MAX_NFT_NUM.add(100_000);
const REF_CREDITS = EVENT_ITEM_BEGIN_NUM.add(1);

const decimal = ethers.BigNumber.from(10).pow(18);
export const TreasureValue = 15 * decimal;

let _balance = ethers.BigNumber.from(0);
let _ref_balance = ethers.BigNumber.from(0);

let _event_registered = false;
function registerEvents() {
    if(!_event_registered && _presaleWithSigner) {
        _presaleWithSigner.on('MonsterAdopted', (_to, gene, grade, referrer) => {
        });
    }
}

async function _authReqest() {
    if(window.ethereum && window.ethereum.isMetaMask) {
        await window.ethereum.request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {}
              }
            ]
        });
    }
};

export async function _updateContracts(connector) {
    const provider = await connector.getProvider();
    if (!provider) {
        throw new Error("Need to sign in");
    }

    _library = getLibrary(provider);
    const chainId = parseInt(await connector.getChainId());
    if (connector instanceof WalletConnectConnector) {
        provider.http = provider.setHttpProvider(chainId);
    }

    // check chainID
    if(!connector.supportedChainIds.includes(chainId)) {
        let err = new UnsupportedChainIdError(chainId, connector.supportedChainIds);
        err.message = `Unsupport chain: ${chainId}, please switch back to polygon`;
        throw err;
    }

    const _acc = await connector.getAccount();
    setupContracts(chainId, _library);

    _currSigner = await _library.getSigner(_acc);
    _presaleWithSigner = presaleContract.connect(_currSigner);
    _monsterNFTWithSigner = monsterNFT.connect(_currSigner);
    _refPrizeWithSigner = refPrize.connect(_currSigner);

    _balance = await _currSigner.getBalance();

    return [connector, _acc];
}

export async function _reconnectToName(name) {

    const connector = connectorsByName[name];
    if (!connector) {
        throw new Error("Need to sign in");
    }

    let isAuthorized = false;

    if (name === "WalletConnect") {
        connector.config.qrcode = false;
        const WalletConnectProvider = await import('@walletconnect/ethereum-provider').then(m => m?.default ?? m);
        const wProvider = new WalletConnectProvider(connector.config);
        isAuthorized = wProvider.connected;
    } else if (name === "Injected") {
        isAuthorized = await connector.isAuthorized();
    }

    if (!isAuthorized) {
        throw new Error("Need to sign in");
    }

    try {
        await connector.activate();
    } catch (e) {
        connector.deactivate();    
        throw e;
    }

    return await _updateContracts(connector);
}

export async function _connectToName(name) {
    const connector = connectorsByName[name];

    console.log("Connecting to " + name);
    if (!connector) {
        throw new Error("Unknown connector: " + name);
    }

    if (name === "WalletConnect") {
        connector.config.qrcode = true;
    }

    try {
        await connector.activate();

        return await _updateContracts(connector);
    } catch (e) {
        connector.deactivate();
        if (name === "WalletConnect") {
            connector.walletConnectProvider = null;
        }

        throw e;
    }
}

function getReferrerID() {
    if(localStorage) {
        return localStorage.getItem('referrer_id') || ethers.constants.AddressZero;
    }
    return ethers.constants.AddressZero;
}

export async function _getOrCreateReflink() {
    if(!_currSigner) throw new Error("Need to connect to the wallet");

    try {
        let res = await gameapi.post('/v1/promo/new_refcode', {
            to: _currSigner._address
        });

        if (res.status === 200) {
            const [ret, refcode] = res.data;
            if (ret !== 1) {
                return getRefUrl(refcode);
            }
            return "";
        } else {
            // error
            console.log("get or create refcode failed:", res);
            throw new Error("Get or create refcode failed");
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on getOrCreate refcode request:", err);
        throw new Error("Error on GetOrCreate refcode");
    }
}

export async function _claimReward(coupon, setClaimSteps) {
    if(!_currSigner) throw new Error("Need to connect to the wallet");

    setClaimSteps({
        step: 1,
        message: "Fetching coupon datas...",
        title: "",
        error: null
    });

    let jdata = null;

    try {
        let res = await gameapi.post('/v1/promo/claim_reward', {
            coupon
        });

        if (res.status === 200) {
            const [cname, _jdata] = res.data;
            if(cname !== coupon) throw new Error("Coupon not matches");
            jdata = _jdata;
        } else {
            // error
            setClaimSteps({
                step: 1,
                message: "",
                title: res.statusText,
                error: res.data
            });
            return;
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on claim reward request:", err);

        if (err instanceof Error) {
            setClaimSteps({
                step: 1,
                message: "",
                title: err.name,
                error: err.message
            });    
        } else {
            setClaimSteps({
                step: 1,
                message: "",
                title: err.response.statusText,
                error: err.response.data
            });    
        }

        return;
    }

    try {
        setClaimSteps({
            step: 2,
            message: "Waiting for claiming reward...",
            title: "",
            error: null
        });
    
        if(jdata) {
            // step 2: call claim method
            const reward_obj = JSON.parse(jdata);
            const rewardValue = ethers.BigNumber.from(reward_obj.val).mul(decimal);
            // console.log("Claim reward 2:", reward_obj, rewardValue);

            const tx = await _refPrizeWithSigner.claimReward(
                ethers.utils.formatBytes32String(coupon),
                reward_obj.ts, rewardValue,
                reward_obj.v, reward_obj.r, reward_obj.s
            );

            let receipt = await tx.wait();
            let reward_event = receipt.events.filter((x) => {
                return (x.event === "RefCreditsCouponReward") && (x.args.to === _currSigner._address)
            })[0];

            // step 3: get result
            if (reward_event) {
                const reward_result = ethers.utils.formatEther(reward_event.args.rewardNum);

                setClaimSteps({
                    step: 3,
                    message: `${reward_result} Gotten`,
                    title: "Done",
                    error: null
                });

                return [coupon, reward_result];
            }
        } else {
            setClaimSteps({
                step: 2,
                message: "",
                title: "Claim Error",
                error: "Invalid reward info, please try later"
            });
        }
    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        // console.log("Error on claim reward request:", err);
        if(err.code === rpcCodes.internal) {
            const metamask_err = err.data;
            if (metamask_err) {
                console.log(metamask_err);
                setClaimSteps({
                    step: 2,
                    message: metamask_err.detailed || metamask_err.message,
                    title: "Claim Error",
                    error: metamask_err.message
                });
            }
        } else if (err.code === errorCodes.provider.userRejectedRequest){
            setClaimSteps({
                step: 2,
                message: err.message,
                title: "User Rejected",
                error: "User Rejected"
            });
        } else {
            setClaimSteps({
                step: 2,
                message: err.message,
                title: "Uncatched error",
                error: "Uncatched error"
            });
        }
    }
}

const PAGE_SIZE = 10;
let _monsterNum = 0;
let _currIdx = 0;

export async function _getMonsterByIdx(_idx) {
    try {
        let res = await gameapi.post('/v1/presale/get_monster_by_idx', {
            owner: _currSigner._address,
            idx: _idx
        });

        if (res.status === 200) {
            const [num, monster] = res.data;
            _monsterNum = num || 0;
            if (_idx > _monsterNum) { _currIdx = _monsterNum - 1; }
            else { _currIdx = _idx; }
            if (_currIdx < 0) _currIdx = 0;

            return [num, _currIdx+1, monster];
        } else {
            // error
            console.log("get next monster failed:", res);
            throw new Error("Get next monster failed");
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on get next monster request:", err);
        throw new Error("Error on get next monster");
    }
}

export async function _getMonster(addNum=0) {
    if(!_currSigner) throw new Error("Need to connect to the wallet");

    _currIdx += addNum;
    if (_currIdx >= _monsterNum) {
        _currIdx = 0;
    }
    if (_currIdx < 0) {
        _currIdx = _monsterNum-1;
    }

    return await _getMonsterByIdx(_currIdx);
}

export async function _adoptMonster(setAdoptSteps) {
    if(!_currSigner) return;

    setAdoptSteps({
        step: 1,
        message: "Fetching avatar datas...",
        title: "",
        error: null
    });

    // step 1: get nonce from chain
    const nonce = await _presaleWithSigner.nonces(_currSigner._address);

    // step 2: request AdoptTicket from game server
    let ticket = null;
    let sig = null;

    try {
        let res = await gameapi.post('/v1/presale/get_adopt_info', {
            sid: user_headers.x_sid,
            to: _currSigner._address,
            nonce: nonce.toNumber()
        });

        if (res.status === 200) {
            [ticket, sig] = res.data;
        } else {
            // error
            console.log("get adopt info failed:", res);
            setAdoptSteps({
                step: 1,
                message: "",
                title: res.statusText,
                error: res.data
            });
            return;
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on adopting monster request:", err);
        setAdoptSteps({
            step: 1,
            message: "",
            title: err.response.statusText,
            error: err.response.data
        });
        return;
    }

    // step 3: call method adoptMonster on chain
    // console.log("ticket received:", ticket, sig);
    setAdoptSteps({
        step: 2,
        message: "Confirm this transaction in your wallet",
        title: "",
        error: null
    });

    // uint grade,
    // uint rarity,
    // bytes32 name,
    // bytes memory gamedata,
    // uint price,
    // uint ts,
    // address to,
    // address referrer,
    // uint8 v, bytes32 r, bytes32 s

    try {
        const price = ethers.BigNumber.from(ticket.price);
        const referrer = getReferrerID();
        const _name = Array(32).fill(0);

        const r = ethers.utils.hexZeroPad(sig.r, 32);
        const s = ethers.utils.hexZeroPad(sig.s, 32);

        const tx = await _presaleWithSigner.adoptMonster(
            ethers.BigNumber.from(ticket.grade),
            ethers.BigNumber.from(ticket.rarity), _name, ticket.gamedata,
            price, ethers.BigNumber.from(ticket.timeStamp),
            ticket.to, referrer,
            sig.v, r, s,
            { value: price }
        );

        // step 4: wait for Event
        const receipt = await tx.wait();
        if (receipt.status) {
            setAdoptSteps({
                step: 3,
                message: "Done",
                title: "",
                error: null
            });
        }
        // else {
        //     setAdoptSteps({
        //         step: 3,
        //         message: "",
        //         title: "Timeout",
        //         error: "Please wait a while, and check your tx: "
        //     });
        // }

    } catch(err) {
        console.log("Error on calling adopting monster contract:", err);
        if(typeof err === "string") {
            setAdoptSteps({
                step: 2,
                message: err,
                title: "Error",
                error: err
            });
        } else if(err.code === rpcCodes.internal) {
            const metamask_err = err.data;
            if (metamask_err) {
                if (metamask_err.code === rpcCodes.invalidInput) {
                    console.log(metamask_err.message);
                    setAdoptSteps({
                        step: 2,
                        message: metamask_err.message,
                        title: "Invalid Input",
                        error: "Invalid Input"
                    });
                } else {
                    console.log(metamask_err);
                    setAdoptSteps({
                        step: 2,
                        message: metamask_err.detailed || metamask_err.message,
                        title: "Uncatched error",
                        error: "Uncatched error"
                    });
                }
            } else {
                setAdoptSteps({
                    step: 2,
                    message: err.message,
                    title: "Uncatched error",
                    error: "Uncatched error"
                });
            }
        } else if (err.code === errorCodes.provider.userRejectedRequest){
            setAdoptSteps({
                step: 2,
                message: err.message,
                title: "User Rejected",
                error: "User Rejected"
            });
        } else {
            setAdoptSteps({
                step: 2,
                message: err.message,
                title: "Uncatched error",
                error: "Uncatched error"
            });
        }
    }
}

export async function _redeemMonster(grade, setRedeemSteps) { 
    if(!_currSigner) return;

    setRedeemSteps({
        step: 1,
        message: "Fetching avatar datas...",
        title: "",
        error: null
    });

    // step 1: get nonce from chain
    const nonce = await _presaleWithSigner.nonces(_currSigner._address);
    
    // step 2: request RedeemTicket from game server
    let ticket = null;
    let sig = null;

    try {
        let res = await gameapi.post('/v1/presale/get_redeem_info', {
            to: _currSigner._address,
            grade,
            nonce: nonce.toNumber()
        });

        if (res.status === 200) {
            [ticket, sig] = res.data;
        } else {
            // error
            console.log("get redeem info failed:", res);
            setRedeemSteps({
                step: 1,
                message: "",
                title: res.statusText,
                error: res.data
            });
            return;
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on redeeming monster request:", err.response);
        setRedeemSteps({
            step: 1,
            message: "",
            title: err.response.statusText,
            error: err.response.data
        });
        return;
    }

    // step 3: call method adoptMonster on chain
    // console.log("redeem ticket received:", ticket, sig);
    setRedeemSteps({
        step: 2,
        message: "Confirm this transaction in your wallet",
        title: "",
        error: null
    });

    // uint grade,
    // uint rarity,
    // bytes32 name,
    // bytes memory gamedata,
    // uint price,
    // uint ts,
    // address to,
    // address referrer,
    // uint8 v, bytes32 r, bytes32 s

    try {
        const price = ethers.BigNumber.from(ticket.price);
        const _name = Array(32).fill(0);

        const r = ethers.utils.hexZeroPad(sig.r, 32);
        const s = ethers.utils.hexZeroPad(sig.s, 32);

        const params = ethers.utils.defaultAbiCoder.encode(
            ["uint", "bytes32", "bytes", "uint", "uint", "uint8", "bytes32", "bytes32"],
            [
                ethers.BigNumber.from(ticket.grade), _name, ticket.gamedata,
                price, ethers.BigNumber.from(ticket.timeStamp),
                sig.v, r, s
            ]
        );

        const tx = await _monsterNFTWithSigner.safeTransferFrom(ticket.to,
            presaleContract.address, REF_CREDITS, price, params);

        // step 4: wait for Event
        const receipt = await tx.wait();
        if (receipt.status) {
            // let monsterSpawnedEvt = receipt.events.filter((x) => {
            //     return (x.event === "MonsterSpawnedSingle") && (x.args._owner === _currSigner._address)
            // })[0];

            // if (monsterSpawnedEvt) {
            //     console.log("monsterSpawnedEvt:", monsterSpawnedEvt.args);
            // }

            setRedeemSteps({
                step: 3,
                message: "Please go to dashboard for monster information",
                title: "Done",
                error: null
            });
        }
        // else {
        //     setRedeemSteps({
        //         step: 3,
        //         message: "",
        //         title: "Timeout",
        //         error: "Please wait a while, and check your tx: "
        //     });
        // }

    } catch(err) {
        console.log("Error on calling redeeming monster contract:", err);
        if(err.code === rpcCodes.internal) {
            const metamask_err = err.data;
            if (metamask_err) {
                if (metamask_err.code === rpcCodes.invalidInput) {
                    console.log(metamask_err.message);
                    setRedeemSteps({
                        step: 2,
                        message: metamask_err.message,
                        title: "Invalid Input",
                        error: "Invalid Input"
                    });
                } else {
                    console.log(metamask_err);
                    setRedeemSteps({
                        step: 2,
                        message: metamask_err.detailed || metamask_err.message,
                        title: "Uncatched error",
                        error: "Uncatched error"
                    });
                }
            } else {
                setRedeemSteps({
                    step: 2,
                    message: err.message,
                    title: "Uncatched error",
                    error: "Uncatched error"
                });
            }
        } else if (errorCodes.provider.userRejectedRequest){
            setRedeemSteps({
                step: 2,
                message: err.message,
                title: "User Rejected",
                error: "User Rejected"
            });
        } else {
            setRedeemSteps({
                step: 2,
                message: err.message,
                title: "Uncatched error",
                error: "Uncatched error"
            });
        }
    }
}

export async function _getRedemptionVouchers() {
    if(!_currSigner || !_monsterNFTWithSigner) return 0;
    return (await _monsterNFTWithSigner.balanceOf(_currSigner._address, REF_CREDITS))/decimal;
}

const REF_SPIN_PRICE = ethers.utils.parseEther("10");

function waitForBlockMinted() {
    if(!_currSigner) throw new Error("Need to connect to the wallet");

    const provider = _currSigner.provider;

    return new Promise((resolve, reject) => {
        let _waitFunc = (...args) => {
            provider.off("block", _waitFunc);
            resolve(...args);
        }
        provider.on("block", _waitFunc);
    });
}

export async function _startRefSpins() {
    if(!_currSigner || !_refPrizeWithSigner) throw new Error("Need to connect to the wallet");

    const from = _currSigner._address;

    let gameRound = await _refPrizeWithSigner.getGameRound(from);

    if (gameRound.isPlaced) {
        try {
            // if game is placed, try to get the rewards
            const tx = await _refPrizeWithSigner.spinWheel();
            let receipt = await tx.wait();
            let reward_events = receipt.events.filter((x) => {return x.event === "RefCreditsRewardGotten"});
            return reward_events;
        } catch(err) {
            console.log("Error on get prize, please try later:", err);
            return;
        }
    }

    // step 1: get nonce from chain
    const nonce = await _refPrizeWithSigner.nonces(from);

    // step 2: request ref spin params from game server
    let params = null;

    try {
        let res = await gameapi.post('/v1/promo/ref_spin_info', {
            from, nonce: nonce.toNumber()
        });

        if (res.status === 200) {
            params = res.data;
            // let result = ethers.utils.defaultAbiCoder.decode(["uint", "uint", "uint8", "bytes32", "bytes32"], params);
            // console.log("gotten:", result);
        } else {
            // error
            console.log("get ref spin info failed:", res);
            // setRefSpinStep({
            //     step: 1,
            //     message: "",
            //     title: res.statusText,
            //     error: res.data
            // });
            return;
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on get ref spin info request:", err.response);
        // setRefSpinStep({
        //     step: 1,
        //     message: "",
        //     title: err.response.statusText,
        //     error: err.response.data
        // });
        return;
    }

    // step 3: call place refprize on chain
    try {
        // await expect(monsterNFT.connect(addr2).safeTransferFrom(
        //     addr2.address, refPrize.address, REF_CREDITS,
        //     ethers.utils.parseEther("10"), params
        // )).to.emit(refPrize, 'RefCreditsBetPlaced').withArgs(
        //     addr2.address, randV, blockNumber+1
        // );

        const tx = await _monsterNFTWithSigner.safeTransferFrom(
            from, refPrize.address, REF_CREDITS,
            REF_SPIN_PRICE, params);

        // step 4: wait for Event
        const receipt = await tx.wait();
        if (receipt.status) {
            // setRefSpinStep({
            //     step: 3,
            //     message: "Done",
            //     title: "",
            //     error: null
            // });
            gameRound = await _refPrizeWithSigner.getGameRound(from);
            console.log("placed:", receipt, gameRound.rewardBlockNumber);

            while(true) {
                let mintedBlock = await waitForBlockMinted();
                console.log("Wait for:", mintedBlock);
                if (gameRound.rewardBlockNumber < mintedBlock) break;
            }

            const tx2 = await _refPrizeWithSigner.spinWheel();
            let prize_receipt = await tx2.wait();
            let reward_events = prize_receipt.events.filter((x) => {return x.event === "RefCreditsRewardGotten"});
            return reward_events;
        }
    } catch(err) {
        console.log("Error on calling refprize contract:", err);
    }
}

export async function _getGameRoundInfo() {
    if(!_currSigner || !_refPrizeWithSigner) throw new Error("Need to connect to the wallet");

    const from = _currSigner._address;

    return await _refPrizeWithSigner.getGameRound(from);
}

export async function _checkGameRoundStatus() {
    let gameRound = await _getGameRoundInfo();

    if(gameRound.isPlaced) {
        let bnumber = await _currSigner.provider.getBlockNumber();
        return gameRound.rewardBlockNumber < bnumber;
    }
    return false;
}

export async function _buyOrOpenTreasureChest(ChestStatus, setChestStatus) {
    if(!_currSigner || !_refPrizeWithSigner) throw new Error("Need to connect to the wallet");

    const from = _currSigner._address;

    let gameRound = await _refPrizeWithSigner.getGameRound(from);

    if (gameRound.isPlaced) {
        if (!ChestStatus.isUnlocked()) {
            setChestStatus(100);
        }

        try {
            if (!ChestStatus.isUnlocked()) {
                while(true) {
                    let mintedBlock = await waitForBlockMinted();
                    console.log("Wait for:", mintedBlock);
                    if (gameRound.rewardBlockNumber < mintedBlock) break;
                }
    
                setChestStatus(200);
            }

            setChestStatus(400);
            // if game is placed, try to get the rewards
            const tx = await _refPrizeWithSigner.spinWheel();
            let receipt = await tx.wait();
            let reward_events = receipt.events.filter((x) => {
                return (x.event === "RefCreditsRewardGotten") && (x.args.from === from)
            });

            let params = reward_events[0];
            if (params.args) {
                console.log("CHEST RETURNED PARAMS:", params.args.prizeId, receipt.events);
                return (params.args.rewardNum / decimal);
            }

            return;
        } catch(err) {
            console.log("Error on get prize, please try later:", err);
            throw err;
        }
    }

    setChestStatus(50);
    // step 1: get nonce from chain
    const nonce = await _refPrizeWithSigner.nonces(from);

    // step 2: request ref spin params from game server
    let params = null;

    try {
        let res = await gameapi.post('/v1/promo/ref_spin_info', {
            from, nonce: nonce.toNumber()
        });

        if (res.status === 200) {
            params = res.data;
            let result = ethers.utils.defaultAbiCoder.decode(["uint", "uint", "uint8", "bytes32", "bytes32"], params);
            console.log("chest info gotten:", params, result, refPrize.address);
        } else {
            // error
            console.log("get treasure chest info failed:", res);
            // setRefSpinStep({
            //     step: 1,
            //     message: "",
            //     title: res.statusText,
            //     error: res.data
            // });
            return;
        }

    } catch(err) {
        // title: err.response.statusText
        // error message: err.response.data
        console.log("Error on get chest info request:", err.response);
        // setRefSpinStep({
        //     step: 1,
        //     message: "",
        //     title: err.response.statusText,
        //     error: err.response.data
        // });
        throw err;
    }

    // step 3: call place refprize on chain
    try {
        // await expect(monsterNFT.connect(addr2).safeTransferFrom(
        //     addr2.address, refPrize.address, REF_CREDITS,
        //     ethers.utils.parseEther("10"), params
        // )).to.emit(refPrize, 'RefCreditsBetPlaced').withArgs(
        //     addr2.address, randV, blockNumber+1
        // );

        const tx = await _monsterNFTWithSigner.safeTransferFrom(
            from, refPrize.address, REF_CREDITS,
            REF_SPIN_PRICE, params);

        // step 4: wait for Event
        const receipt = await tx.wait();
        if (receipt.status) {
            // setRefSpinStep({
            //     step: 3,
            //     message: "Done",
            //     title: "",
            //     error: null
            // });
            gameRound = await _refPrizeWithSigner.getGameRound(from);
            console.log("placed:", receipt, gameRound.rewardBlockNumber);

            setChestStatus(100);

            while(true) {
                let mintedBlock = await waitForBlockMinted();
                console.log("Wait for:", mintedBlock);
                if (gameRound.rewardBlockNumber < mintedBlock) break;
            }

            setChestStatus(200);
        }
    } catch(err) {
        console.log("Error on calling refprize contract:", err);
        throw err;
    }
}
