import { useLayoutEffect, useRef, useState, useEffect } from "react";
import {
  Container, Row, Col, Badge,
} from '../../../../components';
import gsap from "gsap";

export const StartMintTime = new Date('06/01/2022');

const calculateTimeLeft = () => {
    const difference = +StartMintTime - +new Date();
    const lastdiff = difference + 1000;
    let timeLeft = {
        last_days: "00",
        last_hours: "00",
        last_minutes: "00",
        last_seconds: "00",
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
    };
    if (difference > 0) {
        let last_days = Math.floor(lastdiff / (1000 * 60 * 60 * 24));
        let last_hours = Math.floor((lastdiff / (1000 * 60 * 60)) % 24);
        let last_minutes = Math.floor((lastdiff / 1000 / 60) % 60);
        let last_seconds = Math.floor((lastdiff / 1000) % 60);
        let days = Math.floor(difference / (1000 * 60 * 60 * 24));
        let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        let minutes = Math.floor((difference / 1000 / 60) % 60);
        let seconds = Math.floor((difference / 1000) % 60);
        timeLeft = {
            last_days: (last_days<10) ? "0"+last_days : ""+last_days,
            last_hours: (last_hours<10) ? "0"+last_hours : ""+last_hours,
            last_minutes: (last_minutes<10) ? "0"+last_minutes : ""+last_minutes,
            last_seconds: (last_seconds<10) ? "0"+last_seconds : ""+last_seconds,
            days: (days<10) ? "0"+days : ""+days,
            hours: (hours<10) ? "0"+hours : ""+hours,
            minutes: (minutes<10) ? "0"+minutes : ""+minutes,
            seconds: (seconds<10) ? "0"+seconds : ""+seconds,
        };
    }

    return timeLeft;
};

function _applyFigureFlipAnim(el) {
  const q = gsap.utils.selector(el);
  const f_upper = q(".f-upper");
  const f_lower = q(".f-lower");

  gsap.set(f_upper, { rotationX: 0, transformOrigin: "50% 100%" });
  gsap.set(f_lower, { rotationX: 90, transformOrigin: "50% 0%" });

  return gsap
    .timeline()
    .to(f_upper, {
      rotationX: -90,
      duration: 0.5 + 0.2 * Math.random(),
      ease: "Power1.easeIn"
    })
    .to(f_lower, {
      rotationX: 0,
      duration: 0.3,
      ease: "Bounce.easeOut"
    })
    .then((tl) => tl.clear());
}

function _applyFigureAlphaAnim(el) {
  const q = gsap.utils.selector(el);
  const b_upper = q(".b-upper");
  const b_lower = q(".b-lower");

  gsap.set(b_upper, { alpha: 0 });
  gsap.set(b_lower, { alpha: 1 });

  return gsap
    .timeline()
    .to(b_upper, {
      alpha: 1,
      duration: 0.6,
      ease: "Power4.easeIn"
    })
    .to(b_lower, {
      alpha: 0,
      duration: 0.3,
      ease: "Bounce.easeOut"
    });
}

function Figure({ prevNum, num }) {
  const fig_el = useRef();

  useLayoutEffect(() => {
    _applyFigureFlipAnim(fig_el);
    _applyFigureAlphaAnim(fig_el);
  }, [num]);

  return (
    <div className="figure" ref={fig_el}>
      <div className="f-upper upper">
        <span> {prevNum} </span>
      </div>
      <div className="base upper b-upper">
        <span> {num} </span>
      </div>
      <div className="base lower b-lower">
        <span> {prevNum} </span>
      </div>
      <div className="f-lower lower">
        <span> {num} </span>
      </div>
    </div>
  );
}

export function Countdown() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });
  
  return (
    <div style={{width: "100%"}}>
            <Row style={{height: "70px"}}>
                    <Col xs="3">
                    <div className="countdown">
                      <div className="bloc-time days">
                        <Figure prevNum={timeLeft.last_days[0]} num={timeLeft.days[0]} />
                        <Figure prevNum={timeLeft.last_days[1]} num={timeLeft.days[1]} />
                        <div class="colon">:</div>
                      </div>
                    </div>
                    </Col>
                    <Col xs="3">
                    <div className="countdown">
                      <div className="bloc-time hours">
                        <Figure prevNum={timeLeft.last_hours[0]} num={timeLeft.hours[0]} />
                        <Figure prevNum={timeLeft.last_hours[1]} num={timeLeft.hours[1]} />
                        <div class="colon">:</div>
                      </div>
                    </div>
                    </Col>
                    <Col xs="3">
                    <div className="countdown">
                      <div className="bloc-time minutes">
                        <Figure prevNum={timeLeft.last_minutes[0]} num={timeLeft.minutes[0]} />
                        <Figure prevNum={timeLeft.last_minutes[1]} num={timeLeft.minutes[1]} />
                        <div class="colon">:</div>
                      </div>
                    </div>
                    </Col>
                    <Col xs="3">
                    <div className="countdown">
                      <div className="bloc-time seconds">
                        <Figure prevNum={timeLeft.last_seconds[0]} num={timeLeft.seconds[0]} />
                        <Figure prevNum={timeLeft.last_seconds[1]} num={timeLeft.seconds[1]} />
                      </div>
                    </div>
                    </Col>
            </Row>
        </div>
  );
}
