// 
import React from 'react';
import PropTypes from 'prop-types';

const LayoutShowBoxYellow = (props) => (
    <div className="layout__showBoxYellow" >
        { props.children }
    </div>
);

LayoutShowBoxYellow.propTypes = {
    children: PropTypes.node
};
LayoutShowBoxYellow.layoutPartName = "showBoxYellow";

export {
    LayoutShowBoxYellow
};
