import React from "react";

// reactstrap components
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from '../../../components';

import { ReactComponent as WalletConnectIconSvg } from '../../../images/WalletConnect-icon.svg';
import { ReactComponent as MetamaskFoxIconSvg } from '../../../images/metamask-fox.svg';

import useWalletData from '../../../models/WalletModel';

function WalletConnectorModal({isOpen, toggle}) {

    const {
        connectTo, errorMsg,
    } = useWalletData();

    return (
    <Modal centered isOpen={isOpen} toggle={toggle} className="modal-outline-warning">
        <ModalHeader tag="h6">
            <span className="text-warning">
                Connect to Wallet
            </span>
        </ModalHeader>
        <ModalBody>
            <div>
            <Button color="warning" block onClick={() =>{
                connectTo("Injected", () => {
                    console.log("Injected Connected!");
                    toggle();
                });
            }}><MetamaskFoxIconSvg width={30} height={30}/>&nbsp;&nbsp;<b>Injected Metamask</b></Button>
            <Button block onClick={() => {
                connectTo("WalletConnect", () => {
                    console.log("Wallet Connected!");
                    toggle();
                });
            }}><WalletConnectIconSvg width={30} height={30}/>&nbsp;&nbsp;<b>WalletConnect</b></Button>
            </div>
            <div className="text-warning">
                { errorMsg ? <><br/><i className="fas fa-exclamation-circle"> {errorMsg} </i></> : null }
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="link" className="text-warning" onClick={() =>{
                toggle();
            }}>
                Close
            </Button>
        </ModalFooter>
    </Modal>
    );
}

export default WalletConnectorModal;
