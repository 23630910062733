// ShowAvatarModel
import { useState } from "react";
import { createModel } from "hox";

import { GetShowAvatarData, GetFlags, GetGradeID, GetMedalTypeID, GetBaseColor } from './ShowAvatarDataDef';
import { getCombatAbility } from "./CombatModule";

function useShowMonsterAvatar() {
    const [nftId, setNftId] = useState('0');
    const [generation, setGeneration] = useState(0);
    const [rarityScore, setRarityScore] = useState(0);
    const [combatAbility, setCombatAbility] = useState(0);
    const [grade, setGrade] = useState(0);
    const [level, setLevel] = useState(0);
    const [eliteType, setEliteType] = useState(0);
    const [avatarData, setAvatarData] = useState(null);
    const [medalFlags, setMedalFlags] = useState([false,false,false,false,false,false,false]);

    const SetShowMonsterData = (mdata) => {
        UpdateMonsterFlags(mdata.grade, mdata.medalType);
        // 解析怪兽外形数据
        let _newAvatar = GetShowAvatarData(mdata);
        setAvatarData(_newAvatar);
        // 计算战斗能力
        setCombatAbility(getCombatAbility(_newAvatar, GetGradeID(mdata.grade), GetBaseColor(mdata.skillType)));
    }

    // 类型标识数据
    const UpdateMonsterFlags = (mGrad, mType) => {
        setGrade(GetGradeID(mGrad));
        setEliteType(GetMedalTypeID(mType));
        setMedalFlags(GetFlags(GetGradeID(mGrad), GetMedalTypeID(mType)));
    }

    const ResetShowMonsterData = () => {
        setNftId('0');
        setGeneration(0);
        setRarityScore(0);
        setCombatAbility(0);
        setGrade(0);
        setLevel(0);
        setEliteType(0);
        setMedalFlags([false,false,false,false,false,false,false]);
        setAvatarData({
            body: { id:"-1", color:"ffffff" },
            handL: { id:"-1", color:"ffffff" },
            handR: { id:"-1", color:"ffffff" },
            footL: { id:"-1", color:"ffffff" },
            footR: { id:"-1", color:"ffffff" },
            legL: { id:"-1", color:"ffffff" },
            legR: { id:"-1", color:"ffffff" },
            nose: { id:"-1", color:"ffffff" },
            mouth: { id:"-1", color:"ffffff" },
            eyes: { id:"-1", color:"ffffff" },
            ears: { id:"-1", color:"ffffff" },
            beard: { id:"-1", color:"ffffff" },
            hair: { id:"-1", color:"ffffff" },
            frontHornL: { id:"-1", color:"ffffff" },
            frontHornR: { id:"-1", color:"ffffff" },
            backHornL: { id:"-1", color:"ffffff" },
            backHornR: { id:"-1", color:"ffffff" },
            wing: { id:"-1", color:"ffffff" },
            forehead: { id:"-1", color:"ffffff" },
            glasses: { id:"-1", color:"ffffff" },
            pet: { id:"-1", color:"ffffff" },
        });
    }

    return {
        nftId,
        generation,
        rarityScore,
        combatAbility,
        grade,
        level,
        eliteType,
        avatarData,
        medalFlags,

        setNftId,
        setGeneration,
        setRarityScore,
        setCombatAbility,
        setLevel,

        SetShowMonsterData,
        ResetShowMonsterData,
        UpdateMonsterFlags,
    };
}

export default createModel(useShowMonsterAvatar);