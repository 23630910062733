import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router';

// ----------- Pages Imports ---------------
import Error404 from './../../routes/Pages/Error404';

import HomeDefault from './../../routes/Pages/Home';
import HomeFooter from './../../routes/Pages/Home/components/HomeFooter';
import HomeShowBoxUp from './../../routes/Pages/Home/components/HomeShowBoxUp';
import HomeShowBoxDown from './../../routes/Pages/Home/components/HomeShowBoxDown';
import HomeJoinOur from './../../routes/Pages/Home/components/HomeJoinOur';

import MintDefault from './../../routes/Pages/Mint';
import MintComingDefault from './../../routes/Pages/MintComing';
import WhitepaperDefault from './../../routes/Pages/Whitepaper';
import PromotionsDefault from './../../routes/Pages/Promotions';
import { PromoRewardsDefault } from './../../routes/Pages/Promotions/PromoRewards';
import DashboardDefault from './../../routes/Pages/Dashboard';
import FAQDefault from './../../routes/Pages/FAQ';
import TeamDefault from './../../routes/Pages/Team';
import GiveawayDefault from './../../routes/Pages/Giveaway';

// import ComingSoon from './Pages/ComingSoon';
// import Confirmation from './Pages/Confirmation';
// import Danger from './Pages/Danger';
// import ForgotPassword from './Pages/ForgotPassword';
// import LockScreen from './Pages/LockScreen';
// import Login from './Pages/Login';
// import Register from './Pages/Register';
// import Success from './Pages/Success';
// import Timeline from './Pages/Timeline';

//------ Route Definitions --------
// eslint-disable-next-line no-unused-vars
// MintDefault
export const RoutedContent = () => {
    return (
        <Switch>
            { <Redirect from="/" to="/home" exact /> }

            { <Route path="/home" exact component={HomeDefault} /> }          
            { <Route path="/faq" exact component={FAQDefault} /> }

            { <Route path="/mint" exact component={MintComingDefault} /> }
            { <Route path="/mint/:id" exact component={MintComingDefault} /> }

            {/* { <Route path="/whitepaper" exact component={WhitepaperDefault} /> }  */}

            { <Route path="/promotions" exact component={PromotionsDefault} /> } 
            { <Route path="/promorewards/:coupon" exact component={PromoRewardsDefault} /> }

            { <Route path="/dashboard" exact component={DashboardDefault} /> } 

            { <Route path="/team" exact component={TeamDefault} /> } 

            { /*    Pages Routes    */ }
            { <Route component={ Error404 } path="/pages/error-404" /> }
            {/* <Route component={ ComingSoon } path="/pages/coming-soon" />
            <Route component={ Confirmation } path="/pages/confirmation" />
            <Route component={ Danger } path="/pages/danger" />
            <Route component={ Error404 } path="/pages/error-404" />
            <Route component={ ForgotPassword } path="/pages/forgot-password" />
            <Route component={ LockScreen } path="/pages/lock-screen" />
            <Route component={ Login } path="/pages/login" />
            <Route component={ Register } path="/pages/register" />
            <Route component={ Success } path="/pages/success" />
            <Route component={ Timeline } path="/pages/timeline" />

            { /*    404    */ }
            <Redirect to="/pages/error-404" />
        </Switch>
    );
};

//------ Custom Layout Parts --------
export const RoutedNavbars  = () => (
    <Switch>
        { /* Other Navbars: */}
        { /* Default Navbar: */}
        <Route
            component={ HomeDefault.Navbar }
        />
    </Switch>  
);

//------ Custom Layout Parts --------
export const RoutedFooter  = () => (
    <Switch>
        <Route
            component={ HomeFooter }
        />
    </Switch>  
);

//------ Custom Layout Parts --------
export const RoutedShowBoxUp  = () => (
    <Switch>
        <Route
            component={ HomeShowBoxUp }
        />
    </Switch>  
);

//------ Custom Layout Parts --------
export const RoutedShowBoxDown  = () => (
    <Switch>
        <Route
            component={ HomeShowBoxDown }
        />
    </Switch>  
);

//------ Custom Layout Parts --------
export const RoutedBlackBox  = () => (
    <Switch>
        <Route
            component={ HomeJoinOur }
        />
    </Switch>  
);
