// ScrollToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if ( location.pathname.startsWith("/faq") ) {
        var element = document.getElementById('faq');
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
    }
  }, [location]);

  return <>{props.children}</>
};

const scrollToElement = (eleId, offset=0) => {
  var element = document.getElementById(eleId);
  var headerOffset = offset;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition - headerOffset;
  
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}

export {
    ScrollToTop,
    scrollToElement
};