import { useState, useCallback } from "react";
import { createModel } from "hox";
import { ConnectorEvent } from '@web3-react/types'
import { UnsupportedChainIdError } from '@web3-react/core';

import { ethers } from 'ethers';

import {
    _getOrCreateReflink, _connectToName,
    _reconnectToName, _updateContracts,
    _getRedemptionVouchers, _claimReward,
    _redeemMonster, _adoptMonster,
    _getMonster, _getMonsterByIdx
} from '../utils/backdatas';

import useShowMonsterAvatar from './ShowAvatarModel';

import { useToggle } from '../hooks';

import invariant from 'tiny-invariant';

let sign_info = {};

let _activeConnector = null;

function saveSignInfo() {
    if(localStorage) {
        localStorage.setItem("signed_in", sign_info.signedIn);
        localStorage.setItem("connector_name", sign_info.connectorName);
    }
}

function getSignInfo() {
    if(localStorage) {
        sign_info = {
            signedIn: localStorage.getItem('signed_in') || false,
            connectorName: localStorage.getItem('connector_name') || null,
        };
    }
    return sign_info;
}

function normalizeChainId(chainId) {
    if (typeof chainId === 'string') {
      // Temporary fix until the next version of Metamask Mobile gets released.
      // In the current version (0.2.13), the chainId starts with “Ox” rather
      // than “0x”. Fix: https://github.com/MetaMask/metamask-mobile/pull/1275
      chainId = chainId.replace(/^Ox/, '0x');
      var parsedChainId = Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10);
      !!Number.isNaN(parsedChainId) ?  invariant(false, "chainId " + chainId + " is not an integer")  : void 0;
      return parsedChainId;
    } else {
      !Number.isInteger(chainId) ?  invariant(false, "chainId " + chainId + " is not an integer")  : void 0;
      return chainId;
    }
} // https://github.com/ethers-io/ethers.js/blob/d9d438a119bb11f8516fc9cf02c534ab3816fcb3/packages/address/src.ts/index.ts

function normalizeAccount(_address) {
    !(typeof _address === 'string' && _address.match(/^(0x)?[0-9a-fA-F]{40}$/)) ?  invariant(false, "Invalid address " + _address)  : void 0;
    var address = _address.substring(0, 2) === '0x' ? _address : "0x" + _address;
    var chars = address.toLowerCase().substring(2).split('');
    var charsArray = new Uint8Array(40);
  
    for (var i = 0; i < 40; i++) {
      charsArray[i] = chars[i].charCodeAt(0);
    }
  
    var hashed = ethers.utils.arrayify(ethers.utils.keccak256(charsArray));
  
    for (var _i = 0; _i < 40; _i += 2) {
      if (hashed[_i >> 1] >> 4 >= 8) {
        chars[_i] = chars[_i].toUpperCase();
      }
  
      if ((hashed[_i >> 1] & 0x0f) >= 8) {
        chars[_i + 1] = chars[_i + 1].toUpperCase();
      }
    }
  
    var addressChecksum = "0x" + chars.join('');
    !!(address.match(/([A-F].*[a-f])|([a-f].*[A-F])/) && address !== addressChecksum) ?  invariant(false, "Bad address checksum " + address + " " + addressChecksum)  : void 0;
    return addressChecksum;
}

function useWalletData() {
    const [activatingConnector, setActivatingConnector] = useState(null);
    const [isConnected, setConnected] = useState(false);
    const [currAccount, setCurrAccount] = useState('');
    const [errorMsg, setErrMsg] = useState('');
    const [notifiedError, setNotifiedError] = useState(null);
    const [adoptSteps, setAdoptSteps] = useState({});
    const [claimRewardSteps, setClaimRewardSteps] = useState({});
    const [redemptionVouchers, setRedemptionVouchers] = useState(0);
    const [monstersNum, setMonstersNum] = useState(0);
    const [currMonsterIdx, setCurrMonsterIdx] = useState(0);
    const [refcodeLink, setRefLink] = useState('');

    const [ isModalConnectWalletOpen, _toggleModalConnectWallet ] = useToggle(false);
    const [ isClaimStepsPanelOpen, toggleClaimStepsPanel ] = useToggle(false);

    const {
        SetShowMonsterData, setNftId, setGeneration, 
        setLevel, setRarityScore, 
    } = useShowMonsterAvatar();
  
    const toggleModalConnectWallet = useCallback(() => {
        if(isModalConnectWalletOpen) { setErrMsg(''); }
        _toggleModalConnectWallet();
    }, [isModalConnectWalletOpen]);

    const _handleUpdate = useCallback((update) => {
        if (!_activeConnector) {
            throw Error("This should never happen, it's just so Typescript stops complaining");
        }

        var _chainId4 = update.chainId === undefined ? undefined : normalizeChainId(update.chainId);
        // console.log("--------- Update:", update);

        if (_chainId4 !== undefined &&
            !!_activeConnector.supportedChainIds &&
            !_activeConnector.supportedChainIds.includes(_chainId4)
        ) {
            var _error2 = "Unsupported chain id: " + _chainId4 + ". Please switch back to ploygon chain.";
            setNotifiedError(_error2);
        } else {
            setNotifiedError(null);
            // var _account4 = typeof update.account === 'string' ? normalizeAccount(update.account) : update.account;
            _updateContracts(_activeConnector).then(([_, acc]) => {
                setCurrAccount(acc);

                _getRedemptionVouchers().then((v) => {
                    setRedemptionVouchers(v);
                }, (err) => {
                    console.error(err);
                    setErrMsg(err.message);
                });
            }).catch((err) => {
                console.error(err);
                if(err instanceof UnsupportedChainIdError)
                    setNotifiedError(err.message);
            });
        }
    }, [setNotifiedError, setCurrAccount, setRedemptionVouchers]);

    const _handleError = (err) => {
        console.error(err);
    };

    const _handleDeactivate = () => {
        disconnect();
    };

    const _registerEvents = (connector) => {
        // console.log("---registerEvents-->", connector);
        _activeConnector = connector;
        connector.on(ConnectorEvent.Update, _handleUpdate)
            .on(ConnectorEvent.Error, _handleError)
            .on(ConnectorEvent.Deactivate, _handleDeactivate);
    };

    const _deregisterEvents = () => {
        // console.log("---deregisterEvents-->", _activeConnector);
        if(_activeConnector) {
            _activeConnector.off(ConnectorEvent.Update, _handleUpdate)
                .on(ConnectorEvent.Error, _handleError)
                .on(ConnectorEvent.Deactivate, _handleDeactivate);
            _activeConnector = null;
        }
    };

    const getRedemptionVouchers = () => {
        _getRedemptionVouchers().then((v) => {
            setRedemptionVouchers(v);
        }, (err) => {
            console.error(err);
            setErrMsg(err.message);
        });
    };

    const redeemMonster = (grade) => {
        _redeemMonster(grade, setAdoptSteps).then(() => {
        });
    };

    const connectTo = (name, _reslovcb) => {
        _connectToName(name).then(([connector, acc]) => {
            setActivatingConnector(connector);
            setCurrAccount(acc);
            setConnected(true);

            _registerEvents(connector);

            sign_info.signedIn = true;
            sign_info.connectorName = name;
            saveSignInfo();

            if (typeof _reslovcb === "function") _reslovcb();
        }).catch((err) => {
            console.error(err);
            setErrMsg(err.message);
            disconnect();
        });
    };
 
    const reconnectTo = (_reslovcb) => {
        const sinfo = getSignInfo();
        if(sinfo.signedIn && sinfo.connectorName) {
            _reconnectToName(sinfo.connectorName).then(([connector, acc]) => {
                setActivatingConnector(connector);
                setCurrAccount(acc);
                setConnected(true);

                _registerEvents(connector);
    
                if (typeof _reslovcb === "function") _reslovcb(true);
            }, (err) => {
                console.error(err);
                if (err instanceof UnsupportedChainIdError) {
                    setNotifiedError(err.message);
                } else {
                    setErrMsg(err.message);
                    disconnect();

                    if (typeof _reslovcb === "function") _reslovcb(false);    
                }
            });
        }
    };

    const disconnect = () => {
        setNotifiedError(null);

        if(_activeConnector) {
            _activeConnector.deactivate();
            _deregisterEvents();

            setActivatingConnector(null);
            setConnected(false);

            sign_info.signedIn = false;
            sign_info.connectorName = null;
            saveSignInfo();
        }
    };

    const adoptMonster = () => {
        _adoptMonster(setAdoptSteps).then(() => {

        });
    }

    const getLastMonster = () => {
        _getMonsterByIdx(99999999).then(([num, currIdx, currMonster]) => {
            setMonstersNum(num);
            setCurrMonsterIdx(currIdx);

            if(!currMonster) return;
            SetShowMonsterData(currMonster.data);
            setNftId(ethers.BigNumber.from(currMonster.id).toString().padStart(5, '0'));
            setGeneration(currMonster.gen);
            setLevel(currMonster.level);
            setRarityScore(currMonster.rarityScore);
        }, (err) => {
            console.error(err);
        });
    }

    const getMonster = (addNum=0) => {
        _getMonster(addNum).then(([num, currIdx, currMonster]) => {
            setMonstersNum(num);
            setCurrMonsterIdx(currIdx);

            if(!currMonster) return;
            SetShowMonsterData(currMonster.data);
            setNftId(ethers.BigNumber.from(currMonster.id).toString().padStart(5, '0'));
            setGeneration(currMonster.gen);
            setLevel(currMonster.level);
            setRarityScore(currMonster.rarityScore);
        }, (err) => {
            console.error(err);
        });
    }

    const getOrCreateRefLinks = () => {
        _getOrCreateReflink().then((reflink) => {
            setRefLink(reflink);
        }, (err) => {
            console.error(err);
        });
    }

    const claimReward = (coupon) => {
        _claimReward(coupon, setClaimRewardSteps).then((res) => {
            getRedemptionVouchers();
        }, (err) => {
            console.error(err);
        });
    };

    return {
        activatingConnector,
        isConnected,
        errorMsg,
        currAccount,
        connectTo,
        reconnectTo,
        disconnect,
        adoptSteps,
        adoptMonster,
        claimRewardSteps,
        redemptionVouchers,
        getRedemptionVouchers,
        redeemMonster,
        currMonsterIdx,
        monstersNum,
        getLastMonster,
        getMonster,
        getOrCreateRefLinks,
        refcodeLink,
        claimReward,

        notifiedError,
        isModalConnectWalletOpen,
        toggleModalConnectWallet,

        isClaimStepsPanelOpen,
        toggleClaimStepsPanel
    };
}

export default createModel(useWalletData);
