import React, { useRef, useMemo, useEffect, useState } from 'react';
import gsap from "gsap";

import useSimpleWheelData from '../../../models/SimpleWheelModel';

import { Spinner } from '../components/Spinner';

function WheelComponent2() {
  let svgRef = useRef();

  let tlRef = useRef(gsap.timeline());

  const { spinStatus, resetSpin } = useSimpleWheelData();

  useEffect(() => {
    console.log("-------------- STATUS:", spinStatus);
    const q = gsap.utils.selector(svgRef);
    const tl = tlRef.current;
    const spinEle = q("#inner-wheel");

    if (spinStatus === 0) {
      tl
      .to(spinEle, {
        duration: 6,
        rotation: 360,
        transformOrigin: "center center",
        ease: "none",
        repeat: -1
      });
    }

    if (spinStatus === 1) {
      tl
      .clear()
      .to(spinEle, {
        duration: 1.5,
        rotation: 360,
        transformOrigin: "center center",
        ease: "Power1.easeIn",
      }).to(spinEle, {
        duration: 3,
        rotation: 1800,
        transformOrigin: "center center",
        ease: "none",
        repeat: -1
      });
    }

    if (spinStatus === 2) {
      tl
      .clear()
      .to(spinEle, {
        duration: 2,
        rotation: 2160,
        transformOrigin: "center center",
        ease: "Power1.easeOut",
      })
      .set(spinEle, {rotation: 0})
      .then(() => {
        resetSpin();
      });
    }
  }, [spinStatus]);

  return (
      <div id='promo-wheel' ref={svgRef}>
      </div>
  );
}

function TreasureChestStatus({ width }) {
    const { chestStatus } = useSimpleWheelData();

    switch (chestStatus) {
      case 100:
        return <img src="/img/TreasureChest/Lock.png" style={{ width:"35%" }} />;
      case 200:
      case 300:
      case 400:
        return <img src="/img/TreasureChest/Close.png" style={{ width:"35%" }} />;
      case 10:
        return <img src="/img/TreasureChest/Open1.png" style={{ width:"35%" }}  />;
      case 11:
        return <img src="/img/TreasureChest/Open2.png" style={{ width:"35%" }}  />;
      case 12:
        return <img src="/img/TreasureChest/Open3.png" style={{ width:"35%" }}  />;
      case 13:
        return <img src="/img/TreasureChest/Open4.png" style={{ width:"35%" }}  />;
      case 14:
        return <img src="/img/TreasureChest/Open5.png" style={{ width:"35%" }}  />;
    }

    return null;
}

function WheelComponent() {
  const { isWaitting } = useSimpleWheelData();

  return (
    <div style={{position:"relative", width:"100%", paddingTop:"63%"}}>
        <div className='treasure-chest' style={{ position:"absolute", top:"0", left:"0", bottom:"0", right:"0", textAlign:"center" }}>
          <TreasureChestStatus/>
        </div>
        <div style={{ position:"absolute", paddingTop:"33%", top:"0", left:"0", bottom:"0", right:"0", textAlign:"center" }}>
          {
            isWaitting ? <Spinner color="white" width="100px"/> : null
          }
        </div>
    </div>
  );  
}

export default WheelComponent;
