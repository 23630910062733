import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import {
  Layout,
  ThemeProvider,
} from './../../components';

import './App.scss';

import { 
  RoutedContent, RoutedNavbars, 
  RoutedFooter, RoutedShowBoxUp, 
  RoutedShowBoxDown, RoutedBlackBox 
} from './route';

import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from '../../utils/connectors';

import { AccountFloatingWnd } from '../../routes/Pages/components/AccFloatWindow';

const favIcons = [
  { rel: 'icon', type: 'image/x-icon', href: require('./../../images/favicons/favicon.ico').default },

  { rel: 'apple-touch-icon', sizes: '180x180', href: require('./../../images/favicons/apple-touch-icon.png').default },

  { rel: 'icon', type: 'image/png', sizes: '32x32', href: require('./../../images/favicons/favicon-32x32.png').default },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: require('./../../images/favicons/favicon-16x16.png').default }
];

const basePath = process.env.BASE_PATH || '/';

function AppLayout(props) {
  const { children } = props;
  
  return (
      <ThemeProvider initialStyle="dark" initialColor="primary">
          <Layout sidebarSlim favIcons={ favIcons }>
              { /* --------- Navbar ----------- */ }
              <Layout.Navbar>
                  <RoutedNavbars />
              </Layout.Navbar>

              { /* -------- ShowBox ------------*/ }
              <Layout.ShowBoxYellow>
                <RoutedShowBoxUp />
              </Layout.ShowBoxYellow>
              <Layout.ShowBoxBlue>
                <RoutedShowBoxDown />
              </Layout.ShowBoxBlue>

              { /* -------- BlackBox ------------*/ }
              <Layout.BlackBox>
                <RoutedBlackBox />
              </Layout.BlackBox>

              { /* -------- Content ------------*/ }
              <Layout.Content>
                  { children }
                  <AccountFloatingWnd/>
              </Layout.Content>

              { /* --------- Footer ----------- */ }
              <Layout.Footer>
                  <RoutedFooter />
              </Layout.Footer>
          </Layout>
          <svg width="0" height="0">
            <defs>
              <clipPath id="seaBoxCurve" clipPathUnits="objectBoundingBox">
                <path
                  d="M 0,0.1
                    C 0.1 0.4 0.4 1 0.6 1
                    C 0.9 1 1 0.1 1 -0.01
                    L 0 -0.01
                    Z"
                />
              </clipPath>
            </defs>
          </svg>
      </ThemeProvider>
  );
}

function App() {
    return (
        <Router basename={ basePath }>
          <Web3ReactProvider getLibrary={getLibrary}>
            <AppLayout>
                <RoutedContent/>
            </AppLayout>
          </Web3ReactProvider>
        </Router>
    );
}

export default App
