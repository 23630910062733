import React, { useRef, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Button, Card, CardBody, CardTitle, CardText,
    Modal, ModalHeader, ModalBody, ModalFooter
} from '../../../components';

import useWalletData from '../../../models/WalletModel';
import { useToggle } from '../../../hooks';

import WalletConnectorModal from '../components/ConnectorModal';

export const FloatingContainer = ({location, ...props}) => {

	const getNavigatorLocation = () => {
		switch (location) {
			case 'top-left':
				return {
                    top: 10,
                    right: 'auto',
                    bottom: 'auto',
                    left: 10,
				};
			case 'top-right':
				return {
                    top: 10,
                    right: 10,
                    bottom: 'auto',
                    left: 'auto',
				};
			case 'bottom-left':
				return {
                    top: 'auto',
                    right: 'auto',
                    bottom: 10,
                    left: 10,
				};
			default:
				return {
                    top: 'auto',
                    right: 10,
                    bottom: 10,
                    left: 'auto',
                };
		}
	};

    const container = getNavigatorLocation();

	return (
		<>
            <div
                style={{
                    position: "fixed",
                    top: container.top,
                    right: container.right,
                    bottom: container.bottom,
                    left: container.left,
                    width: 40,
                    height: 40,
                }}
            >
                { props.children }
            </div>
        </>
    );
};

FloatingContainer.propTypes = {
    children: PropTypes.node
};

export function AccountFloatingWnd() {
    const {
        isConnected, notifiedError,
        isModalConnectWalletOpen, toggleModalConnectWallet,
        reconnectTo, disconnect, currAccount
    } = useWalletData();
    const [isActive, toggleFloatingLoginPanel ] = useToggle(false);

    const rootClass = classNames('fp-login', {
        'fp-login--active': isActive,
    });

    const abbrAcc = `${currAccount.substring(0, 6)}...${currAccount.substring(currAccount.length - 4)}`;

    useEffect(() => {
        reconnectTo((res) => { console.log("Connected:", res); });
    }, []);

    return (
        <>
        <div className={ rootClass }>
            <Button
                color="warning"
                className="fp-login__trigger"
                onClick={() => { toggleFloatingLoginPanel(); }}
            >
                {
                    isConnected ? (
                        <i className="fa fa-light fa-user-check"></i>

                    ) : (
                        <i className="fa fa-light fa-user"></i>
                    )
                }
            </Button>
            <Card body inverse style={{
                backgroundColor: '#ffa101', borderColor: '#f07227'
            }}>
                {
                    isConnected ? (
                        null
                    ) : (
                        <CardTitle style={{color: '#000'}}>Click to login:</CardTitle>
                    )
                }

                {
                    isConnected ? (
                        <>
                        <CardText style={{color: '#000', fontWeight: '800'}}>
                            { abbrAcc }
                        </CardText>    
                        <Button block color="danger" onClick={() => {
                            disconnect();
                            toggleFloatingLoginPanel();
                        }}>
                            <i className="fa fa-users"> <b> Disconnect</b></i>
                        </Button>   
                        </>
                    ) : (
                        <Button block color="danger" onClick={() => {
                            toggleModalConnectWallet();
                            toggleFloatingLoginPanel();
                        }}>
                            <i className="fas fa-user"> <b> Sign in now</b></i>
                        </Button>
                    )
                }
            </Card>
        </div>
        <WalletConnectorModal isOpen={isModalConnectWalletOpen} toggle={toggleModalConnectWallet}></WalletConnectorModal>
        <Modal centered isOpen={!!notifiedError} className="modal-outline-danger">
            <ModalBody>
                <div className="text-danger">
                    { notifiedError ? <i className="fas fa-exclamation-circle"> {notifiedError} </i> : null }
                </div>
            </ModalBody>
        </Modal>
        </>
    )
};
