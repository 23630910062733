//SimpleWheelModel

import { useState } from "react";
import { createModel } from "hox";

import {
    _startRefSpins,
    _buyOrOpenTreasureChest,
    TreasureValue
} from '../utils/backdatas';

let _spinStatus = 0;

const ChestStatus = {
    _chestStatus: 0,
    setStatus: function (status) {
        this._chestStatus = status;
    },
    isUnlocked: function () {
        return this._chestStatus === 300;
    },
    status: function () {
        return this._chestStatus;
    }
};

function useSimpleWheelData() {
    const [spinStatus, setStatus] = useState(0);
    const [chestStatus, setChestStatus] = useState(0);
    const [isWaitting, setWaiting] = useState(false);
    const [info, setInfo] = useState('Click the Button to Get Lucky');

    const resetSpin = () => {
        _spinStatus = 0;
        setStatus(_spinStatus);
    }

    const startSpin = () => {
        if (_spinStatus === 0) {
            _spinStatus = 1;
            setStatus(_spinStatus);

            _startRefSpins().then((params) => {
                _spinStatus = 2;
                setStatus(_spinStatus);
            }, (err) => {
                _spinStatus = 2;
                setStatus(_spinStatus);
            });
        }
    };

    const _setChestStatus = (val) => {
        ChestStatus.setStatus(val);
        if ([50, 100, 200, 400].indexOf(val) !== -1) {
            setWaiting(true);
        }
        setChestStatus(val);
    }

    const resetChest = () => {
        _setChestStatus(0);
        setInfo('Click the Button to Get Your Good Luck');
    }

    const buyOrOpenTreasureChest = () => {
        if (ChestStatus._chestStatus === 0 || ChestStatus._chestStatus === 300) {
            _buyOrOpenTreasureChest(ChestStatus, _setChestStatus).then((params) => {
                console.log("CHEST INFO PARAMS:", params);
                if (params != null && params != undefined) {
                    setInfo("You Got "+params+" Monster Redemption Vouchers");
                    if (params < 10) _setChestStatus(10);
                    else if (params < 20) _setChestStatus(11);
                    else if (params < 30) _setChestStatus(12);
                    else if (params < 100) _setChestStatus(13);
                    else _setChestStatus(14);
                } else {
                    _setChestStatus(300);
                }

                setWaiting(false);
            }, (err) => {
                if (ChestStatus._chestStatus > 300) _setChestStatus(300);
                if (ChestStatus._chestStatus < 300) _setChestStatus(0);

                setStatus(_spinStatus);

                setWaiting(false);
            });
        }
    };

    return {
        chestStatus,
        resetChest,
        // spinStatus,
        // resetSpin,
        // startSpin,
        buyOrOpenTreasureChest,

        isWaitting,

        info, 
        setInfo,
    };
}

export default createModel(useSimpleWheelData);