/*global PIXI*/
/*eslint no-undef: "error"*/
/*eslint no-undef: ["error", { "typeof": true }] */

// add names
const AvatarAddNames = {
    1150:"1128add", 1151:"1128add",
    2150:"2115add", 2151:"2115add", 2152:"2115add", 2155:"2155add", 2156:"2155add", 2157:"2155add",
	2160:"2160add", 2161:"2160add", 2170:"2170add", 2171:"2170add", 2172:"2170add", 2173:"2170add", 2174:"2170add",
    2527:"2527add", 2550:"2527add", 2551:"2527add",
	2627:"2627add", 2650:"2627add", 2651:"2627add",
	2750:"2750add", 2751:"2750add", 2752:"2750add", 2753:"2750add",
	2850:"2850add", 2851:"2850add", 2852:"2850add", 2853:"2850add",
};

function toColorObj(_c) {
    const c = _c.trim();
    let ret = null;

    if (c.length !== 6) return ret;
    
    const r = parseInt(c.substr(0,2), 16);
    const g = parseInt(c.substr(2,2), 16);
    const b = parseInt(c.substr(4,2), 16);

    if (isNaN(r) || isNaN(g) || isNaN(b)) return ret;

    return new window.dragonBones.ColorTransform(1.0, r/0xff, g/0xff, b/0xff);
}

export default class DragonBonesScene extends PIXI.Container {
    constructor(skeleton, jsonDataList, texDataList) {
        super();
        this.TEXT_NAME = "text";

        this.skeleton = skeleton;
        this.texJsonList = jsonDataList;
        this.texPngList = texDataList;
        this.hasText = false;
        this.ARMATURE_DISPLAY_NAME = "M";

        this.xOffset = 0;
        this.yOffset = 0;
        this.animationReady = false;

        this.dragonbonesName = "MA";
        this.armatureName = "M";

        this.animationName = ["idle1", "idle2", "idle3", "attack1", "attack2", "attack3"];

        this.updateAvatar = this.updateAvatar.bind(this);
        this.setAvatarData = this.setAvatarData.bind(this);
        this.replaceSlotDisplay = this.replaceSlotDisplay.bind(this);
        this.createAvatar = this.createAvatar.bind(this);
        this.getRandAnimationName = this.getRandAnimationName.bind(this);

        this.setScale = this.setScale.bind(this);

        this.avatarShowData = {};
        this.armatureDisplay = null;

        this.ticker = new PIXI.Ticker();
        this.ticker.stop();
    }

    _startRenderTick() {
        PIXI.Ticker.shared.minFPS = 60;
    }

    setAvatarData(newAvatarData) {
        if (newAvatarData === null) {
            return;
        }

        this.createAvatar();

        this.avatarShowData = {
            "01": newAvatarData.body,               // body
            "02": newAvatarData.handL,              // left hand
            "03": newAvatarData.handR,              // right hand
            "04": newAvatarData.footL,              // left foot
            "05": newAvatarData.footR,              // right foot
            "06": newAvatarData.legL,               // left leg
            "07": newAvatarData.legR,               // right leg
            "10": newAvatarData.nose,               // nose
            "11": newAvatarData.mouth,              // mouth
            "12": newAvatarData.eyes,               // eyes
            "13": newAvatarData.ears,               // ears
            "20": newAvatarData.beard,              // beard
            "21": newAvatarData.hair,               // hair
            "25": newAvatarData.frontHornL,         // left front horn
            "26": newAvatarData.frontHornR,         // right front horn
            "27": newAvatarData.backHornL,          // left back horn
            "28": newAvatarData.backHornR,          // right back horn
            "30": newAvatarData.wing,               // left wing
            "31": newAvatarData.wing,               // right wing
            "35": newAvatarData.forehead,           // forehead
            "36": newAvatarData.glasses,            // glasses
            "37": newAvatarData.pet,                // pet
        };

        this.updateAvatar();
    }

    updateAvatar() {
        if ( Object.keys(this.avatarShowData).length < 1 ) {
            return;
        }
        if (!this.armatureDisplay) {
            return;
        }

        const factory = window.dragonBones.PixiFactory.factory;

        Object.entries(this.avatarShowData).forEach(([k, v]) => { 
            const addKey = k + "add"; 
            const itemName = k + v.id;
            const itemAddName = (itemName in AvatarAddNames)?AvatarAddNames[itemName] : itemName + "add";
            const itemColor = (itemName in AvatarAddNames)?"ffffff" : v.color;

            const itemAdd = this.armatureDisplay.armature.getSlot(addKey);
            const item = this.armatureDisplay.armature.getSlot(k);

            if ( v.id === "-1" ) {
                if (item) item.visible = false;
                if (itemAdd) itemAdd.visible = false;
                return;
            }

            const itemData = this.armatureDisplay.armature.armatureData.getSlot(k);
            
            const color = toColorObj(itemColor);
            if(!color) {
                color = new window.dragonBones.ColorTransform(1.0, 1, 1, 1);
            }
            if (itemData) itemData.color = color;
            if (item) item._setColor(color);

            this.replaceSlotDisplay(factory, k, itemName, item);
            this.replaceSlotDisplay(factory, addKey, itemAddName, itemAdd);
        })
    }

    replaceSlotDisplay(factory, slotName, displayName, slot) {
        if (!slot) return;

        const armatureData = this.armatureDisplay.armature.armatureData;
        if (armatureData === null || armatureData.defaultSkin === null) {
            slot.visible = false;
            return;
        }

        const displayData = armatureData.defaultSkin.getDisplay(slotName, displayName);
        if (displayData) {
            slot.visible = true;
            factory.replaceDisplay(slot, displayData);
        }
        else {
            slot.visible = false;
        }
    }

    getRandAnimationName() {
        let id = Math.round(Math.random()*this.animationName.length);
        if (id >= this.animationName.length) {
            id = this.animationName.length - 1;
        }
        return this.animationName[id];
    }

    createAvatar() {
        if (this.armatureDisplay) this.removeChild(this.armatureDisplay);

        const factory = window.dragonBones.PixiFactory.factory;
        this.armatureDisplay = factory.buildArmatureDisplay(this.armatureName, this.dragonbonesName);

        // this.armatureDisplay.scale.x = 0.768;
        // this.armatureDisplay.scale.y = 0.768;

        this.addChild(this.armatureDisplay);
        this.armatureDisplay.animation.play(this.getRandAnimationName(), 1);
    }

    _onStart() {
        const factory = window.dragonBones.PixiFactory.factory;
        factory.parseDragonBonesData(this.skeleton);
        for (let i=0; i<this.texJsonList.length; i++) {
            factory.parseTextureAtlasData(this.texJsonList[i], this.texPngList[i].texture);
        }

        this.ticker.add((deltaTime) => {
            if (!this.armatureDisplay) {
                return;
            }
            if (this.armatureDisplay.animation.isCompleted) {
                this.armatureDisplay.animation.play(this.getRandAnimationName(), 1);
            }
        });
        this.ticker.start();

        // 初始测试数据
        //this.setAvatarData(_testData);
    }

    setScale(s) {
        this.scale.x = s;
        this.scale.y = s;
        // console.log("setScale: ", this.scale);
    }
}
