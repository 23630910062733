import React from "react";

// reactstrap components
// import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import {
    Container, Row, Col, Nav, NavItem, NavLink
} from '../../../../components';

function HomeShowBoxDown() {
    return (
        <Container>
        <Row className="align-items-center justify-content-xl-between">
            <Col xs="5">
            </Col>

            <Col xl="7">
                <img src="/img/island2.png"/>
            </Col>
        </Row>
      </Container>
    );
}

export default HomeShowBoxDown;