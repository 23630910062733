import React from "react";
import { NavLink as Link } from 'react-router-dom';

// reactstrap components
// import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import {
    Container, Row, Col, Nav, NavItem, NavLink
} from '../../../../components';

// href="#"
// rel="noopener noreferrer"
// target="_blank"

function HomeFooter() {
    return (
      <Container style={{padding:"15px"}}>
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xs="3">
            <Row className="justify-content-center">
            <NavLink className="text-muted">
              © 2022{" "}
            </NavLink>
            <NavLink tag={ Link } to="/team">
                    <span style={{color:"#fff"}}>
                    HORAC STUDIO
                    </span>
            </NavLink>
            </Row>
          </Col>

          <Col xl="5">
            <Container className="nav-footer justify-content-center">
                <Row>
                <Nav>
              <NavItem>
                <NavLink tag={ Link } to="/home">
                        <span>
                            Home
                        </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={ Link } to="/team">
                        <span>
                            Team
                        </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={ Link } to={{pathname: "/whitepaper/index.html"}} target="_blank">
                        <span>
                        WhitePaper 
                        </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={ Link } to="/promotions">
                        <span>
                        Promotions
                        </span>
                </NavLink>
              </NavItem>
              </Nav>
              </Row>
            </Container>
          </Col>
          <Col>
            <Nav className="nav-footer justify-content-center">
              <NavItem>
                <NavLink
                  href="https://t.me/TheMonsterKingsChannel"
                  rel="The Monster Kings Channel"
                  target="_blank"
                >
                  <i className="fab fa-telegram fa-2x"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://horacstudio.medium.com/"
                  rel="The Monster Kings"
                  target="_blank"
                >
                  <i className="fab fa-medium fa-2x"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://twitter.com/TMorganer"
                  rel="The Monster Kings"
                  target="_blank"
                >
                  <i className="fab fa-twitter fa-2x"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.youtube.com/channel/UCyrWJGz9gWVpfPsijXCpMlw"
                  rel="The Monster Kings"
                  target="_blank"
                >
                  <i className="fab fa-youtube fa-2x"></i>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <hr style={{width:"100%", borderStyle:"dashed", borderColor:"#fff"}} />
        <Row style={{paddingLeft:"50px"}}>
        SMART CONTRACT ADDRESS:
        </Row>
        <Row style={{paddingLeft:"50px"}}>
          <li>
          Monster NFT:&nbsp;
          <a style={{color:"#0cf"}}
            href="https://polygonscan.com/address/0xC79cC223B99c51363c29c5b520a3D13707504947"
            rel="Monster NFT Contract Address"
            target="_blank"
          >
          0xC79cC223B99c51363c29c5b520a3D13707504947
          </a>
          </li>
        </Row>
        <Row style={{paddingLeft:"50px"}}>
          <li>
          Presale:&nbsp;
          <a style={{color:"#0cf"}}
            href="https://polygonscan.com/address/0x7c72c7aCaa71e775ebcbb746f7876E8029e2FB3e"
            rel="Presale Contract Address"
            target="_blank"
          >
          0x7c72c7aCaa71e775ebcbb746f7876E8029e2FB3e
          </a>
          </li>
        </Row>
        <Row style={{paddingLeft:"50px"}}>
          <li>
          Promotion:&nbsp;
          <a style={{color:"#0cf"}}
            href="https://polygonscan.com/address/0x8382e7Eb03914e80F97dC90A1bE6796A9f78773c"
            rel="Promotion Contract Address"
            target="_blank"
          >
            0x8382e7Eb03914e80F97dC90A1bE6796A9f78773c
          </a>
          </li>
        </Row>
      </footer>
      </Container>
    );
}

export default HomeFooter;