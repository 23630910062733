// DashboardDefault
import React, { useEffect } from 'react';
import { withPageConfig } from
    '../../../components/Layout/withPageConfig';
import {
    Container, Row, Col, Badge, Button,
} from '../../../components';

import { withModel } from "hox";
import MonsterViewPixi from '../components/MonsterViewPixi';

import useWalletData from '../../../models/WalletModel';
import useShowMonsterAvatar from '../../../models/ShowAvatarModel';

// 未登录
function NoLoginPanel() {
    const {
        isConnected, toggleModalConnectWallet
    } = useWalletData();

    return (
        <div>
            <Container className="infoBox">
                <Row className="align-items-center justify-content-xl-between">
                    <h4 className="home-titlecenterfont" style={{color:"#000"}}>
                        <i className="fas fa-exclamation-circle"> You Are Not Logged In</i>
                    </h4>
                </Row>
                <Row>
                <div>
                    <li><b>
                    Login to view the monsters' information.
                    </b></li>
                </div>
                </Row>
                <Row style={{height:"20px"}}></Row>
                <Row>
                    <Button block color="danger" onClick={() => {
                        toggleModalConnectWallet();
                    }}>
                        <i className="fas fa-user"> <b> Sign in now</b></i>
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

function HeadInfo() {

    const { monstersNum, currMonsterIdx } = useWalletData();

    return (
        <Row className="align-items-center justify-content-xl-between">
            <h4 className="home-titlecenterfont">
                <i className="fas fa-paw"/>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                Your Monsters 
                &nbsp;&nbsp;&nbsp;&nbsp;
                { currMonsterIdx } / { monstersNum } 
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fas fa-paw"/>
            </h4>
        </Row>
    );
}

function DashboardDefault() {

    const { getMonster, getLastMonster, currAccount, isConnected } = useWalletData();
    const { avatarData, medalFlags, nftId, generation, 
        rarityScore, combatAbility, grade, level, 
    } = useShowMonsterAvatar();

    useEffect(() => {
        if (isConnected) getLastMonster();
    }, [isConnected, currAccount]);

    return (
        <>
        { isConnected ? null:<NoLoginPanel /> }
        <Container>
            <HeadInfo/>
        </Container>
        <Container>
            <Row className="align-items-center justify-content-xl-between">
                <Container className="ani-panel">
                    <Row>
                        <Col className="accordion" style={{textAlign:"center"}}>
                            <Row className="monsterDataBox"  style={{textAlign:"left"}}>
                                <Col xl="4">
                                    ID: #{nftId}<br/>
                                    Generation: {generation}
                                </Col>
                                <Col xl="4">
                                    Rarity Score: {rarityScore}<br/>
                                    Combat Ability: {combatAbility}
                                </Col>
                                <Col xl="4">
                                    Grade: {grade}<br/>
                                    Level: {level}
                                </Col>
                            </Row>
                            <MonsterViewPixi
                                avatarData={avatarData}
                                medalFlags={medalFlags}
                            /><br/>
                            <Container>
                                <Row className="mintBuyPanel">
                                    <Col xl="5">
                                        <Button color="light" block onClick={() => getMonster(-1)}>
                                            <i className="fas fa-hand-point-left"> <b>Previous</b></i>
                                        </Button>
                                    </Col>
                                    <Col xl="2">
                                    </Col>
                                    <Col xl="5">
                                        <Button color="light" block onClick={() => getMonster(1)}>
                                            <b>Next</b> <i className="fas fa-hand-point-right"> </i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="align-items-center justify-content-xl-between" style={{paddingLeft:"60px", paddingRight:"60px", paddingTop:"10px"}}>
            <b>Note: When the treasure map is opened, it allows monsters to mint tokens (MCT) in the forest.</b>
            </Row>
        </Container>
        </>
    );
}

const ExtendedDashboardDefault = withPageConfig(DashboardDefault);

export {
    ExtendedDashboardDefault as DashboardDefault
};