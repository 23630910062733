import React from "react";

// reactstrap components
// import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { NavLink as Link } from 'react-router-dom';
import {
    Container, Row, Col, Nav, NavItem, NavLink, Badge, Button
} from '../../../../components';

//import TimeLeftShower from './TimeLeftShower';
import { Countdown, StartMintTime } from './Countdown';

function MainCountdown() {
    return (
        <Row>
            <Col xs="1">
            </Col>
            <Col xs="11">
                <Countdown />
                <Row>
                    <Col xs="3">
                        <div className="home-showtime"> Days </div>
                    </Col>
                    <Col xs="3">
                        <div className="home-showtime"> Hours </div>
                    </Col>
                    <Col xs="3">
                        <div className="home-showtime"> Minutes </div>
                    </Col>
                    <Col xs="3">
                        <div className="home-showtime"> Seconds </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

function TreasureMapOpenText() {
    return (
        <>
        Treasure Map will open on <br/> {StartMintTime.toUTCString()}
        </>
    );
}

// Treasure map open
function TreasureMapSoonText() {
    return (
        <>
        Next Event: Mint Open <br/> Are you ready?
        </>
    );
}

function NormalText() {
    return (
        <>
        <div className="home-showtime">
            Monsters' hearts are beating
        </div>
        <br/>
        </>
    );
}

function HomeShowBoxUp() {
    let _showCountDown = false;

    return (
        <Container>
        <Row className="align-items-center justify-content-xl-between">
            <Col xs="5">
            <Container style={{width: "445px",}}>
                <Row>
                    <div className="home-slogan">
                        You Create Your Monster
                    </div>
                </Row>

                <Row>
                    <div className="home-showtime">
                        { _showCountDown ? <TreasureMapOpenText /> : <TreasureMapSoonText /> }
                    </div>
                </Row>

                <Row style={{height: "10px",}}></Row>

                <Row>
                    <Col xs="4">
                        <img src="/img/egg1.png"/>
                    </Col>
                    <Col xs="4">
                        <img src="/img/egg2.png"/>
                    </Col>
                    <Col xs="4">
                        <img src="/img/egg3.png"/>
                    </Col>
                </Row>

                { _showCountDown ? <MainCountdown /> : <NormalText /> }
                
                <Row>
                    <Col xs="2">
                    </Col>
                    <Col xs="9">
                        <Button color="danger" style={{width: "310px"}} tag={ Link } to="/mint">
                            <i className="fas fa-medal">  Free to Create</i>
                        </Button>
                    </Col>
                    <Col xs="1">
                    </Col>
                </Row>
            </Container>
            </Col>

            <Col xl="7">
                <div style={{height: "480px"}}>
                    <img src="/img/island1.png" style={{position: "relative", top:"285px"}}/>
                    <img src="/img/avatar.png" style={{position: "relative", left:"110px", top:"-150px", zIndex:"100"}}/>
                </div>
            </Col>
        </Row>
      </Container>
    );
}

export default HomeShowBoxUp;