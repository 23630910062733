import { useState } from "react";
import { createModel } from "hox";
// import { ConnectorEvent } from '@web3-react/types'

import { gameapi, user_headers } from '../utils/apiclient';

import { 
    GetGradeID, GetMedalTypeID, GetShowAvatarData_Part, GetFlags, 
 } from './ShowAvatarDataDef';

let currMonster = null;
let showMonsterData = {};

function usePresaleAdoptMonster() {
    const [grade, setGrade] = useState(0);
    const [level, setLevel] = useState(0);
    const [eliteType, setEliteType] = useState(0);
    const [price, setPrice] = useState(20);
    const [avatarData, setAvatarData] = useState(null);

    const [wantGrade, setWantGrade] = useState(2);
    const [medalFlags, setMedalFlags] = useState([false,false,false,false,false,false,false]);

    const levelup = () => {
        if (!currMonster) return;

        showMonsterData.level += 1;
        setLevel(showMonsterData.level);

        if (showMonsterData.level >= 10) {
            setMedalFlags(GetFlags(showMonsterData.grade, showMonsterData.eliteType));
        }

        let _newAvatar = showMonsterData.avatarData;
        switch (showMonsterData.level) {
            case 1:
                // body, handL, handR, footL, footR, legL, legR
                {
                    _newAvatar.body = GetShowAvatarData_Part(currMonster.body);
                    _newAvatar.handL = GetShowAvatarData_Part(currMonster.handL);
                    _newAvatar.handR = GetShowAvatarData_Part(currMonster.handR);
                    _newAvatar.footL = GetShowAvatarData_Part(currMonster.footL);
                    _newAvatar.footR = GetShowAvatarData_Part(currMonster.footR);
                    _newAvatar.legL = GetShowAvatarData_Part(currMonster.legL);
                    _newAvatar.legR = GetShowAvatarData_Part(currMonster.legR);

                    _newAvatar.nose = GetShowAvatarData_Part({id:0, color:currMonster.nose.color});
                    _newAvatar.mouth = GetShowAvatarData_Part({id:0, color:currMonster.mouth.color});
                    _newAvatar.eyes = GetShowAvatarData_Part({id:0, color:currMonster.eyes.color});
                }
                break;
            case 2:
                // create nose
                {
                    _newAvatar.nose = GetShowAvatarData_Part(currMonster.nose);
                    _newAvatar.mouth = GetShowAvatarData_Part({id:1, color:currMonster.mouth.color});
                    _newAvatar.eyes = GetShowAvatarData_Part({id:1, color:currMonster.eyes.color});
                }
                break;
            case 3:
                // create mouth
                {
                    _newAvatar.mouth = GetShowAvatarData_Part(currMonster.mouth);
                    _newAvatar.eyes = GetShowAvatarData_Part({id:2, color:currMonster.eyes.color});
                }
                break;
            case 4:
                // create eyes
                {
                    _newAvatar.eyes = GetShowAvatarData_Part(currMonster.eyes);
                }
                break;
            case 5:
                // create ears
                {
                    _newAvatar.ears = GetShowAvatarData_Part(currMonster.ears);
                }
                break;
            case 6:
                // create frontHornL, frontHornR
                {
                    _newAvatar.frontHornL = GetShowAvatarData_Part(currMonster.frontHornL);
                    _newAvatar.frontHornR = GetShowAvatarData_Part(currMonster.frontHornR);
                }
                break;
            case 7:
                // create beard, hair
                {
                    _newAvatar.hair = GetShowAvatarData_Part(currMonster.hair);
                    _newAvatar.beard = GetShowAvatarData_Part(currMonster.beard);
                }
                break;
            case 8:
                // create backHornL, backHornR
                {
                    _newAvatar.backHornL = GetShowAvatarData_Part(currMonster.backHornL);
                    _newAvatar.backHornR = GetShowAvatarData_Part(currMonster.backHornR);
                }
                break;
            case 9:
                // create wing
                {
                    _newAvatar.wing = GetShowAvatarData_Part(currMonster.wing);
                }
                break;
            case 10:
                // create forehead, glasses, pet
                {
                    _newAvatar.forehead = GetShowAvatarData_Part(currMonster.forehead);
                    _newAvatar.glasses = GetShowAvatarData_Part(currMonster.glasses);
                    _newAvatar.pet = GetShowAvatarData_Part(currMonster.pet);
                }
                break;
        }

        setAvatarData({..._newAvatar});
    }

    const _resetAvatarData = () => {
        showMonsterData = {
            level: 0,
            grade: GetGradeID(currMonster.grade),
            eliteType: GetMedalTypeID(currMonster.medalType),
            avatarData: {
                body: { id:"-1", color:"ffffff" },
                handL: { id:"-1", color:"ffffff" },
                handR: { id:"-1", color:"ffffff" },
                footL: { id:"-1", color:"ffffff" },
                footR: { id:"-1", color:"ffffff" },
                legL: { id:"-1", color:"ffffff" },
                legR: { id:"-1", color:"ffffff" },
                nose: { id:"-1", color:"ffffff" },
                mouth: { id:"-1", color:"ffffff" },
                eyes: { id:"-1", color:"ffffff" },
                ears: { id:"-1", color:"ffffff" },
                beard: { id:"-1", color:"ffffff" },
                hair: { id:"-1", color:"ffffff" },
                frontHornL: { id:"-1", color:"ffffff" },
                frontHornR: { id:"-1", color:"ffffff" },
                backHornL: { id:"-1", color:"ffffff" },
                backHornR: { id:"-1", color:"ffffff" },
                wing: { id:"-1", color:"ffffff" },
                forehead: { id:"-1", color:"ffffff" },
                glasses: { id:"-1", color:"ffffff" },
                pet: { id:"-1", color:"ffffff" },
            },
        }

        setGrade(showMonsterData.grade);
        setEliteType(showMonsterData.eliteType);
        setPrice(currMonster.price);
        
        setMedalFlags([false,false,false,false,false,false,false]);
    }

    const requestNewMonster = (grade) => {
        gameapi.post('/v1/presale/get_random_monster', {
            sid: user_headers.x_sid,
            grade,
        }).then((res) => {
            if (res.status == 200) {
                user_headers.x_sid = res.headers["x-sid"];
                currMonster = res.data;
                _resetAvatarData();
                levelup();
            } else {
                console.log("get random monster failed:", res);
            }
        }).catch((err) => {
            // error.message
            console.log("Error on request new monster:", err.response);
        });
    }

    const requestReferrerCode = (rid) => {
        if (rid && rid.length > 0) {
            gameapi.post('/v1/promo/get_refcode', {
                rid,
            }).then((res) => {
                if (res.status == 200) {
                    const [refcode, referrerAddr] = res.data;
                    if(localStorage) {
                        if (refcode === rid &&
                            referrerAddr && referrerAddr.length > 0 &&
                            !localStorage.getItem('referrer_id'))
                            return localStorage.setItem('referrer_id', referrerAddr);
                    }
                } else {
                    console.log("get referrer code failed:", res);
                }
            }).catch((err) => {
                // error.message
                console.log("Error on request referrer code:", err.response);
            });    
        }
    }

    return {
        grade,
        level,
        eliteType,
        price,
        avatarData,
        requestNewMonster,
        levelup,
        wantGrade,
        medalFlags,
        setWantGrade,
        requestReferrerCode
    };
}

export default createModel(usePresaleAdoptMonster);
