import {
    Accordion, NavLink
} from '../../../../components';

// 备用问号图标：far fa-question-circle
function FAQ() {
    return (
        <div className="accordion">
            <Accordion initialOpen className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> What blockchain platform does The Monster Kings use?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    The Monster Kings aims to be a {' '}
                    <strong>
                        Multi-Blockchain Collectible Casual Game
                    </strong>
                    , but now only supports <strong>polygon</strong> chain in presale version. As the project moves forward, we will link to more and more platforms.
                </Accordion.Body>
            </Accordion>
            <Accordion className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> How do I adopt a monster?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    In the pre-sale version, you can adopt the monster of your choice directly on the <strong>Mint</strong> page.
                </Accordion.Body>
            </Accordion>
            <Accordion className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> Which wallet can I use?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    In general, any Layer 2 compatible wallet can be used.<br/>
                    However, we recommend that you use <strong>Metamask</strong> wallet. This 
                    <a className='text-primary-light' href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask#add-the-polygon-network-manually"> link </a>
                    is the official instruction on how to <strong>add the polygon network manually</strong>.
                </Accordion.Body>
            </Accordion>
            <Accordion className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> Why first Monsters are special?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    The monsters sold in the pre-sale are all <strong>Generation 0</strong>, and there are only 10,000 in total.<br/>
                    All Generation 0 monsters are selected and created by the player.<br/>
                    After the pre-sale ends, no more Generation 0 monsters will be available for sale.
                </Accordion.Body>
            </Accordion>
            <Accordion className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> What is the difference between the types of monsters?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    Each monster consists of 21 parts, each with an average of dozens of different features, and hundreds of colors. This gives each created monster a great deal of uniqueness.<br/>
                    The probability of each feature being randomly selected depends on their respective <strong>rarity</strong>. And the colour affects the monster's <strong>combat attributes</strong> in some directions. Of course, the fighting ability of a monster depends mainly on its grade and level.<br/>
                    The rarity and fighting ability of the monster in turn affects the amount of income in many ways. And it is the key to all games.<br/>
                    A more detailed description can be found in the <strong>white paper</strong>.
                </Accordion.Body>
            </Accordion>
            <Accordion className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> How to get MCT and MNT?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    In the presale version, you can get MCT in the <strong>Treasure Map</strong>.<br/>
                    When the game goes online, you can get the <strong>MCT</strong> with method as below:
                    <li>Buy and sell on the exchange</li>
                    <li>Play the game and earn the MCT rewards</li>
                    <li>Join our affiliate marketing program</li>
                    <li>Staking for reward</li>
                    You can not get MNT In presale version. When the game goes online, you can get the <strong>MNT</strong> with method as below:
                    <li>Deposit 1 MATIC and you will get 10 MNT</li>
                    <li>Play the game and earning the MNT rewards</li>
                    <li>Stake MCT and you will share the game revenue settled in MNT</li>
                </Accordion.Body>
            </Accordion>
            <Accordion className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> Can I sell or buy MCT and MNT?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    Of course <strong>YES</strong>, you can buy and sell on the exchange when the game goes online.
                </Accordion.Body>
            </Accordion>
            <Accordion initialOpen className="bg-card">
                <Accordion.Header className="bg-cardup">
                    <strong><i className="fas fa-question-circle"> Do you have more question(s)?</i></strong>
                </Accordion.Header>
                <Accordion.Body className="bg-carddown">
                    If you don{'’'}t find an answer to your questions, don{'’'}t worry!&nbsp;
                    <a style={{color:"#06f"}}
                    href="https://t.me/+IkRkGiZmJk81NDUx"
                    rel="The Monster Kings"
                    target="_blank"
                    >
                    Join our Telegram group
                    </a>
                    &nbsp;and we and other community members will be happy to answer any questions you may have.
                </Accordion.Body>
            </Accordion>
        </div>
    );
}

export default FAQ;