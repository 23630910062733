
import { getAvatarScore } from "./ScoreEvaluation";
import { getCombatAbility } from "./CombatModule";

// skill type
const SKILL_MAXID = 7;

// grade type
const GRADE_WHITE = 0;
const GRADE_GREEN = 1;
const GRADE_BLUE = 2;
const GRADE_PURPLE = 3;
const GRADE_RED = 4;
const GRADE_MAXID = 5;

// level max
const LEVEL_FULL = 10;

// grade growth rates
const GradeGrowthRates = [0.2, 0.35, 0.5, 0.65, 0.8];

// part item id
const AvatarParts = {
    nose:"10", mouth:"11", eyes:"12", ears:"13", beard:"20", hair:"21", 
    frontHornL:"25", frontHornR:"26", backHornL:"27", backHornR:"28",
    wingL:"30", wingR:"31", forehead:"35", glasses:"36", pet:"37",
};

// items
const AvatarItems = {
    nose: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"],
    mouth: ["02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "50", "51", "52"],
    eyes: ["03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53"],
    ears: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"],
    frontHorn: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "50", "51"],
    hair: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "50", "51", "52", "55", "56", "57", "60", "61", "70", "71", "72", "73", "74"],
    beard: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
    backHorn: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "50", "51", "52", "53"],
    wing: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70"],
    forehead: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78"],
    glasses: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33"],
    pet: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26"],
};

// Avatar Item Ponds
const AvatarItemPonds = [
    {nose: [], mouth: [], eyes: [], ears: [], frontHorn: [], hair: [], beard: [], backHorn: [], wing: [], forehead: [], glasses: [], pet: []},
    {nose: [], mouth: [], eyes: [], ears: [], frontHorn: [], hair: [], beard: [], backHorn: [], wing: [], forehead: [], glasses: [], pet: []},
    {nose: [], mouth: [], eyes: [], ears: [], frontHorn: [], hair: [], beard: [], backHorn: [], wing: [], forehead: [], glasses: [], pet: []},
    {nose: [], mouth: [], eyes: [], ears: [], frontHorn: [], hair: [], beard: [], backHorn: [], wing: [], forehead: [], glasses: [], pet: []},
    {nose: [], mouth: [], eyes: [], ears: [], frontHorn: [], hair: [], beard: [], backHorn: [], wing: [], forehead: [], glasses: [], pet: []},
];

// colors
const AvatarColors = [
    ["ee4645","ff8a3c","ffce3f","6bc44f","29cfd0","119fec","965bbb"], // base
    ["d52120","e26919","e3b01c","4cb22b","12b5b6","0985c9","7d3ca5"], // dark1
    ["b70100","b94a00","c59300","30980f","009394","0069a3","681f94"], // dark2
    ["ff8483","ffa86e","ffda6e","90db78","5cdfe0","44bdff","ba87da"], // light1
    ["ffbebd","ffd0b0","ffebaf","bdecae","a1edee","87d5ff","dfbdf4"], // light2
    ["ad3812","0f2daa","c0278b","6d1f07","a7061e","169d4b","9433b7","2191e7","ce5b19","fdb92c","e20a17","5056cc","556384","ee6285","a9507d","de8bb0","b1325b","678f6a","418371","e59d57","757fa7","ee6171","1cb3b7","7569ab","b2cb53","58b4a5","779e5b","30a5c4","ed7a49","e2ad4e","579427","c50824","9c0e74","388dc1","185b93","2c8b8e","d62754","b62d72","9f5c15","4975b4"], // dark other
    ["cadfe9","feca74","fdf38f","fddcd2","ffcece","ffd4a3","efe68e","a8c4f0","eec8ec","daf6d1","c8f6f7","c5eafe","eedcfa","dee9ff"], // light other
    ["dcdcdc","aaaaaa","787878","464646","282828","efefef","fafafa"],   // black and white
];

// color list
const AvatarColorList = {
    1106:[2, 5], 1111:[2, 5], 1113:[2, 5], 1114:[2, 5], 1119:[2, 5], 
    1130:[2, 5], 1131:[2, 5], 1132:[2, 5], 1133:[2, 5], 
    1214:[2, 5], 1215:[2, 5], 1216:[2, 5], 1219:[2, 5], 1221:[2, 5], 
    1238:[2, 5], 1239:[2, 5], 2113:[2, 5], 
    2505:[2, 5], 2510:[2, 5], 2605:[2, 5], 2610:[2, 5], 
    2702:[2, 5], 2707:[2, 5], 2720:[2, 5], 2802:[2, 5], 2807:[2, 5], 2820:[2, 5], 
};

function getRandColor(clist=null) {
    if (!clist) {
        clist = [...Array(AvatarColors.length).keys()];
    }

    let chooseListID =  clist[Math.floor(Math.random() * clist.length)];
    let tmpColorList = AvatarColors[chooseListID];
    let retColor = tmpColorList[Math.floor(Math.random() * tmpColorList.length)];

    return retColor;
}

function getRandColorByID(id) {
    let clist = (id in AvatarColorList) ? AvatarColorList[id] : null;
    return getRandColor(clist);
}

function getItemNumList(itemsLen, peakValue, grade, minNum=1) {
    let numList = Array(itemsLen);
    let peakId = Math.floor(itemsLen * grade / GRADE_MAXID);
    let endID = Math.floor(itemsLen * (grade+2) / GRADE_MAXID);
    let stepAdd = (peakValue-1) / (endID-peakId);
    let stepSub = stepAdd/4; //(peakValue/4)/peakId
    if (endID >= itemsLen) endID = itemsLen - 1;
    let tmpIdNum = 0;
    for ( let i=0; i<itemsLen; i++ ) {
        tmpIdNum = i - peakId;
        if (tmpIdNum >= 0) numList[i] = Math.round(peakValue - tmpIdNum*stepAdd);
        else numList[i] = Math.round(peakValue + tmpIdNum*stepSub);
        if (numList[i] < minNum) numList[i] = minNum;
        if (i > endID) numList[i] = 0;
    }
    return numList;
}

function getRandItemFromPond(pondname, grade, peakValue=10) {
    if (AvatarItemPonds.length < GRADE_MAXID) return "00";
    if (pondname in AvatarItemPonds[grade]) {
        let itemlist = AvatarItemPonds[grade][pondname];
        if (itemlist.length < 1) {
            // create items
            let numList = getItemNumList(AvatarItems[pondname].length, peakValue, grade);
            for (let i=0; i<numList.length; i++) {
                itemlist.push( ...Array(numList[i]).fill(AvatarItems[pondname][i]) );
            }
            itemlist.sort( ()=>{return .5-Math.random();} );
        }

        return itemlist.pop();
    }
    return "00";
}

class MonsterData {
    constructor(grade, skillType=Math.floor(Math.random() * SKILL_MAXID), level=0) {
        // skill type
        this.skillType = skillType;
        // grade
        this.grade = grade;
        // level
        this.level = level;
        // rarity score
        this.score = 0;
        // combat ability
        this.combatAbility = 0;

        // avatar data
        this.avatarData = {};
        this.resetAvatarData();

        // bind
        this.resetAvatarData = this.resetAvatarData.bind(this);
        this.levelUp = this.levelUp.bind(this);
    }

    // 重置
    resetAvatarData(skillType=Math.floor(Math.random() * SKILL_MAXID), level=0) {
        this.level = level;
        this.skillType = skillType;
        this.score = 0;
        this.combatAbility = 0;
        this.avatarData = {
            body: { id:"-1", color:"ffffff" },
            handL: { id:"-1", color:"ffffff" },
            handR: { id:"-1", color:"ffffff" },
            footL: { id:"-1", color:"ffffff" },
            footR: { id:"-1", color:"ffffff" },
            legL: { id:"-1", color:"ffffff" },
            legR: { id:"-1", color:"ffffff" },
            nose: { id:"-1", color:"ffffff" },
            mouth: { id:"-1", color:"ffffff" },
            eyes: { id:"-1", color:"ffffff" },
            ears: { id:"-1", color:"ffffff" },
            beard: { id:"-1", color:"ffffff" },
            hair: { id:"-1", color:"ffffff" },
            frontHornL: { id:"-1", color:"ffffff" },
            frontHornR: { id:"-1", color:"ffffff" },
            backHornL: { id:"-1", color:"ffffff" },
            backHornR: { id:"-1", color:"ffffff" },
            wing: { id:"-1", color:"ffffff" },
            forehead: { id:"-1", color:"ffffff" },
            glasses: { id:"-1", color:"ffffff" },
            pet: { id:"-1", color:"ffffff" },
        };
    }

    // 成长一级
    levelUp() {
        if ( this.level >= LEVEL_FULL ) return false;

        let grade_rate = GradeGrowthRates[this.grade];

        this.level += 1;
        switch (this.level) {
            case 1:
                // create body, handL, handR, footL, footR, legL, legR
                {
                    this.avatarData.body.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.handL.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.handR.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.footL.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.footR.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.legL.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.legR.id = `0${Math.floor(Math.random() * 3)+1}`;
                    this.avatarData.body.color = AvatarColors[0][this.skillType];
                    this.avatarData.handL.color = AvatarColors[Math.floor(Math.random() * 5)][this.skillType];
                    this.avatarData.handR.color = AvatarColors[Math.floor(Math.random() * 5)][this.skillType];
                    this.avatarData.footL.color = AvatarColors[Math.floor(Math.random() * 5)][this.skillType];
                    this.avatarData.footR.color = AvatarColors[Math.floor(Math.random() * 5)][this.skillType];
                    this.avatarData.legL.color = AvatarColors[Math.floor(Math.random() * 5)][this.skillType];
                    this.avatarData.legR.color = AvatarColors[Math.floor(Math.random() * 5)][this.skillType];

                    this.avatarData.nose.id = "00";
                    this.avatarData.mouth.id = "00";
                    this.avatarData.eyes.id = "00";

                    if (this.grade >= GRADE_BLUE) {
                        if (Math.random() < (grade_rate + 0.2)) this.avatarData.handL.id = this.avatarData.handR.id;
                        if (Math.random() < (grade_rate + 0.2)) this.avatarData.footL.id = this.avatarData.footR.id;
                        if (Math.random() < (grade_rate + 0.2)) this.avatarData.legL.id = this.avatarData.legR.id;
                    }
                }
                break;
            case 2:
                // create nose
                {
                    this.avatarData.nose.id = getRandItemFromPond("nose", this.grade);
                    this.avatarData.nose.color = getRandColorByID(AvatarParts.nose+this.avatarData.nose.id);

                    this.avatarData.mouth.id = "01";
                    this.avatarData.eyes.id = "01";
                }
                break;
            case 3:
                // create mouth
                {
                    this.avatarData.mouth.id = getRandItemFromPond("mouth", this.grade);
                    this.avatarData.mouth.color = getRandColorByID(AvatarParts.mouth+this.avatarData.mouth.id);

                    this.avatarData.eyes.id = "02";
                }
                break;
            case 4:
                // create eyes
                {
                    this.avatarData.eyes.id = getRandItemFromPond("eyes", this.grade);
                    this.avatarData.eyes.color = getRandColorByID(AvatarParts.eyes+this.avatarData.eyes.id);
                }
                break;
            case 5:
                // create ears
                {
                    this.avatarData.ears.id = getRandItemFromPond("ears", this.grade);
                    this.avatarData.ears.color = getRandColorByID(AvatarParts.ears+this.avatarData.ears.id);
                }
                break;
            case 6:
                // create frontHornL, frontHornR
                {
                    let fh_id = getRandItemFromPond("frontHorn", this.grade);
                    let fh_color = getRandColorByID(AvatarParts.frontHornL+this.avatarData.frontHornL.id);

                    this.avatarData.frontHornL.id = fh_id;
                    this.avatarData.frontHornR.id = fh_id;
                    this.avatarData.frontHornL.color = fh_color;
                    this.avatarData.frontHornR.color = (Math.random() < grade_rate) ? getRandColorByID(AvatarParts.frontHornR+this.avatarData.frontHornR.id) : fh_color;

                    if (Math.random() > (grade_rate + 0.4)) {
                        if (Math.random() < 0.5) this.avatarData.frontHornL.id = "00";
                        else this.avatarData.frontHornR.id = "00";
                    }
                }
                break;
            case 7:
                // create beard, hair
                {
                    let hasBeard = true;
                    if (Math.random() < grade_rate) {
                        this.avatarData.hair.id = getRandItemFromPond("hair", this.grade);
                        this.avatarData.hair.color = getRandColorByID(AvatarParts.hair+this.avatarData.hair.id);
                        if (Math.random() < grade_rate) hasBeard = true;
                        else hasBeard = false;
                    }
                    
                    if(hasBeard) {
                        this.avatarData.beard.id = getRandItemFromPond("beard", this.grade);
                        this.avatarData.beard.color = getRandColorByID(AvatarParts.beard+this.avatarData.beard.id);
                    }
                }
                break;
            case 8:
                // create backHornL, backHornR
                {
                    let bh_id = getRandItemFromPond("backHorn", this.grade);
                    let bh_color = getRandColorByID(AvatarParts.backHornL+this.avatarData.backHornL.id);

                    this.avatarData.backHornL.id = bh_id;
                    this.avatarData.backHornR.id = bh_id;
                    this.avatarData.backHornL.color = bh_color;
                    this.avatarData.backHornR.color = (Math.random() < grade_rate) ? getRandColorByID(AvatarParts.backHornR+this.avatarData.backHornR.id) : bh_color;

                    if (Math.random() > (grade_rate + 0.4)) {
                        if (Math.random() < 0.5) this.avatarData.backHornL.id = "00";
                        else this.avatarData.backHornR.id = "00";
                    }
                }
                break;
            case 9:
                // create wing
                {
                    this.avatarData.wing.id = getRandItemFromPond("wing", this.grade);
                    this.avatarData.wing.color = getRandColorByID(AvatarParts.wingL+this.avatarData.wing.id);
                }
                break;
            case 10:
                // create forehead, glasses, pet
                {
                    let hasForehead = false;
                    let hasGlasses = false;
                    let hasPet = false;
                    if (Math.random() < (grade_rate)) {
                        hasPet = true;
                        if (Math.random() < (grade_rate)) {
                            hasForehead = true;
                            if (Math.random() < (grade_rate)) {
                                hasGlasses = true;
                            }
                        }
                    }
                    else {
                        if (Math.random() < (grade_rate)) {
                            hasGlasses = true;
                            if (Math.random() < (grade_rate)) {
                                hasForehead = true;
                            }
                        }
                        else hasForehead = true;
                    }

                    if (hasForehead) {
                        this.avatarData.forehead.id = getRandItemFromPond("forehead", this.grade);
                        this.avatarData.forehead.color = getRandColorByID(AvatarParts.forehead+this.avatarData.forehead.id);
                    }
                    if (hasGlasses) {
                        this.avatarData.glasses.id = getRandItemFromPond("glasses", this.grade);
                        this.avatarData.glasses.color = getRandColorByID(AvatarParts.glasses+this.avatarData.glasses.id);
                    }
                    if (hasPet) {
                        this.avatarData.pet.id = getRandItemFromPond("pet", this.grade);
                        this.avatarData.pet.color = getRandColorByID(AvatarParts.pet+this.avatarData.pet.id);
                    }
                }
                break;

            default:
                return false;
        }

        this.score = getAvatarScore(this.avatarData);
        this.combatAbility = getCombatAbility(
            this.avatarData, this.grade, 
            AvatarColors[0][this.skillType], this.level
        );

        return true;
    }
}

function CreateRandomMonsterEgg(grade=2) {
    return new MonsterData(grade);
}

export {
    CreateRandomMonsterEgg
};