import React from 'react';
import { Link } from 'react-router-dom';
import {
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarToggler,
    UncontrolledCollapse
} from './../../../../components';

import { NavbarNavigation } from
    './../../../components/Navbars/NavbarNavigation';

export const LogoLayoutNavbar = () => (
    <React.Fragment>
        <Navbar expand="lg" dark>
            <Link to="/" className="navbar-brand mr-0 mr-sm-3">
                <img src="/img/logo.png"  width="30%" height="30%" />
            </Link>

            <UncontrolledCollapse navbar toggler="#navbar-navigation-toggler">
                <NavbarNavigation pills fill/>
            </UncontrolledCollapse>

            <Nav className="ml-auto" pills>
                <NavItem>
                    <NavLink tag={ NavbarToggler } id="navbar-navigation-toggler" className="b-0">
                        <i className="fa fa-fw fa-bars"></i>
                    </NavLink>
                </NavItem>
            </Nav>
            
            <Nav className="ml-auto" pills>
            </Nav>
        </Navbar>
    </React.Fragment>
);
