import React from 'react';
import PropTypes from 'prop-types';

const LayoutBlackBox = (props) => (
    <div className="layout__blackBox" >
        { props.children }
    </div>
);

LayoutBlackBox.propTypes = {
    children: PropTypes.node
};
LayoutBlackBox.layoutPartName = "blackBox";

export {
    LayoutBlackBox
};
