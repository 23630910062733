import { useState, useEffect } from "react";

function loadScript(src) {
  return new Promise(function(resolve, reject) {
    const s = document.createElement('script');
    let r = false;

    s.type = 'text/javascript';
    s.src = src;
    s.async = true;

    s.onerror = function(err) {
      reject(err, s);
    };

    s.onload = s.onreadystatechange = function() {
      // console.log("-ONLOAD: ", this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true;
        resolve(s);
      }
    };

    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
}

function loadAllScriptsSeq(srcs) {
  return new Promise(async (resolve, reject) => {

    for (const src of srcs) {
      if (!src) {
        continue;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        script = await loadScript(src);
      }
      
      if (script) {
        //console.log("script loaded:", script);
      } else {
        reject(new Error(`Loading ${src} failed.`));
      }
    }

    resolve();
  });
}

// (function() {
//   var id = 0;

//   function generateId() { return id++; };

//   Object.prototype.id = function() {
//       var newId = generateId();

//       this.id = function() { return newId; };

//       return newId;
//   };
// })();

function useSeqScripts(srcs) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(srcs.length > 0 ? "loading" : "idle");

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      loadAllScriptsSeq(srcs).then(() => {
        setStatus("ready");
      }).catch((err) => {
        setStatus("error");
        console.error(err);
      });
    },
    [srcs] // Only re-run effect if script src changes
  );
  return status;
}

// Hook
function useScript(src) {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState(src ? "loading" : "idle");

    useEffect(
      () => {
        // Allow falsy src value if waiting on other data needed for
        // constructing the script URL passed to this hook.
        if (!src) {
          setStatus("idle");
          return;
        }
        // Fetch existing script element by src
        // It may have been added by another intance of this hook
        let script = document.querySelector(`script[src="${src}"]`);
        if (!script) {
          // Create script
          script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.setAttribute("data-status", "loading");
          // Add script to document body
          document.body.appendChild(script);
          // Store status in attribute on script
          // This can be read by other instances of this hook
          const setAttributeFromEvent = (event) => {
            script.setAttribute(
              "data-status",
              event.type === "load" ? "ready" : "error"
            );
          };
          script.addEventListener("load", setAttributeFromEvent);
          script.addEventListener("error", setAttributeFromEvent);
        } else {
          // Grab existing script status from attribute and set to state.
          setStatus(script.getAttribute("data-status"));
        }
        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event) => {
          setStatus(event.type === "load" ? "ready" : "error");
        };
        // Add event listeners
        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);
        // Remove event listeners on cleanup
        return () => {
          if (script) {
            script.removeEventListener("load", setStateFromEvent);
            script.removeEventListener("error", setStateFromEvent);
          }
        };
      },
      [src] // Only re-run effect if script src changes
    );
    return status;
}

export { useSeqScripts, useScript };
